import styled from "styled-components";

import { H1Props, headingStyles } from "./H1";

interface H3Props extends H1Props {}

const H3 = styled.h3<H3Props>`
  font-size: 24px;
  line-height: 140%;

  ${headingStyles};
`;

export default H3;
