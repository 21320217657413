import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UpdateDictionariesCoefficientModel } from "../../../../viewModels/Dictionaries/DictionariesCoefficientModel";

import { IDictionariesCoefficientsPageState, LoadedDictionariesCoefficientsPayloadType, SortObject } from "./types";

import IDictionariesCoefficientDto from "../../../../dto/Dictionaries/IDictionariesCoefficientDto";

const initialState: IDictionariesCoefficientsPageState = {
  loading: true,
  loadingData: true,

  coefficients: undefined!,

  total: undefined!,
  pageSize: 10,
  page: 1,

  sort: undefined!,

  coefficientIdToEdit: undefined!,
  isEditCoefficientDrawerLoading: false,
  isEditCoefficientDrawerOpen: false,
};

const dictionariesCoefficientsSlice = createSlice({
  name: "dictionariesCoefficients",
  initialState,
  reducers: {
    loadCoefficients(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadCoefficientsSuccess(state, action: PayloadAction<LoadedDictionariesCoefficientsPayloadType>) {
      const { coefficients, total } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.coefficients = coefficients;
      state.total = total;
    },
    loadCoefficientsError(state) {
      state.loading = false;
      state.loadingData = false;
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;
      state.loadingData = true;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.loadingData = true;
    },
    sortCoefficients(state, action: PayloadAction<SortObject>) {
      state.sort = {
        key: action.payload.key,
        order: action.payload.order,
      };
      state.loadingData = true;
    },
    search(state) {
      state.loadingData = true;
    },
    openEditCoefficientDrawer(state, action: PayloadAction<number | undefined>) {
      state.coefficientIdToEdit = action.payload!;
      state.isEditCoefficientDrawerOpen = true;
    },
    closeEditCoefficientDrawer(state) {
      if (!state.isEditCoefficientDrawerLoading) {
        state.coefficientIdToEdit = undefined!;
        state.isEditCoefficientDrawerOpen = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    updateCoefficient(state, action: PayloadAction<UpdateDictionariesCoefficientModel>) {
      state.isEditCoefficientDrawerLoading = true;
    },
    updateCoefficientSuccess(state, action: PayloadAction<IDictionariesCoefficientDto>) {
      const index = state.coefficients.findIndex((x) => x.id === action.payload.id);
      state.coefficients[index] = action.payload;
      state.isEditCoefficientDrawerLoading = false;
      state.isEditCoefficientDrawerOpen = false;
    },
    updateCoefficientError(state) {
      state.isEditCoefficientDrawerLoading = false;
    },
  },
});

export default dictionariesCoefficientsSlice;
