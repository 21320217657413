import React from "react";

// import ReCAPTCHA from "react-google-recaptcha";

import { Button, Form } from "antd";

import { useTranslation } from "react-i18next";

import InputMask from "react-input-mask";

import Input from "../../../../components/MyInput";
import { TextAreaWithFormLabel } from "../../../../components/Form/FormItemsWithLabel";

import { ContactFormInputModel, ContactFormProps } from "./types";
import { phoneNumberRegExp } from "../../../../utils/regExps";

// function ContactFrom({ onSendApplication, isDataLoading, isCaptchaError, onCaptchaChange }: ContactFormProps) {
function ContactFrom({ onSendApplication, isDataLoading }: ContactFormProps) {
  const { t } = useTranslation("translations");
  const [form] = Form.useForm();

  // const captchaRef: any = useRef<HTMLLinkElement>(null);

  const onFinish = ({ fullname, phoneNumber, email, message }: ContactFormInputModel) => {
    // onSendApplication({ fullname, phoneNumber, email, message }, captchaRef.current.getValue());
    onSendApplication({ fullname, phoneNumber, email, message });
  };

  // useEffect(() => {
  //   if (isCaptchaError) {
  //     form.setFields([
  //       {
  //         name: "recaptcha",
  //         errors: [t("contact_form.captcha_error")],
  //       },
  //     ]);
  //   } else {
  //     form.setFields([
  //       {
  //         name: "recaptcha",
  //         errors: [],
  //       },
  //     ]);
  //   }
  // }, [form, t, isCaptchaError]);

  return (
    <>
      <Form onFinish={onFinish} form={form}>
        <Form.Item name="fullname" rules={[{ required: true, message: t("enter_fullname")! }]}>
          <Input placeholder={t("fullname")!} label={t("fullname")!} mandatory />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          rules={[
            () => ({
              validator(_, value: string) {
                if (phoneNumberRegExp.test(value)) {
                  return Promise.resolve();
                }
                if (!value) {
                  return Promise.reject(t("enter_phone_number"));
                }
                return Promise.reject(t("enter_phone_number_in_format"));
              },
            }),
          ]}
        >
          <InputMask mask="+7 (999) 999 - 9999">
            <Input placeholder={t("phone_number")!} label={t("phone_number")!} mandatory />
          </InputMask>
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t("enter_email")! },
            { type: "email", message: t("wrong_email")! },
          ]}
        >
          <Input placeholder="Email" label="Email" mandatory />
        </Form.Item>
        <Form.Item name="message" rules={[{ required: true, message: t("enter_message")! }]} style={{ marginBottom: "40px" }}>
          <TextAreaWithFormLabel placeholder={t("message")!} label={t("message")!} maxLength={300} showCount mandatory autoSize />
        </Form.Item>
        {/* <Form.Item name="recaptcha">
          <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY!} ref={captchaRef} onChange={onCaptchaChange} />
        </Form.Item> */}
        <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isDataLoading}>
          {t("send")}
        </Button>
      </Form>
    </>
  );
}

export default React.memo(ContactFrom);
