import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IErrorFile, IImportPageState, LoadFilePayloadType } from "./types";

const initialState: IImportPageState = {
  loading: false,
  loadingData: false,

  isImportActivate: false,
  errors: [],

  isFileModalOpen: false,
  isFileLoading: false,
  file: undefined!,
  filename: "",
  typeId: 0,
};

const importRoutesSlice = createSlice({
  name: "importRoutes",
  initialState,
  reducers: {
    setIsImportActivate(state, action: PayloadAction<boolean>) {
      state.isImportActivate = action.payload;
    },
    uploadDocument(state, action: PayloadAction<LoadFilePayloadType>) {
      const { file, filename, typeId } = action.payload;
      state.isFileLoading = true;
      state.file = file;
      state.filename = filename;
      state.typeId = typeId;
    },
    uploadDocumentError(state) {
      state.isFileLoading = false;
    },
    resetErrorsState(state) {
      state.errors = [];
    },
    setErrors(state, action: PayloadAction<IErrorFile>) {
      const errors = state.errors.filter((error) => error.fileName !== action.payload.fileName);
      errors.push(action.payload);
      state.errors = errors;
    },
    deleteSuccessFileFromErrors(state, action: PayloadAction<string>) {
      state.errors = state.errors.filter((error) => error.fileName !== action.payload);
    },
    openFileModal(state) {
      state.isFileModalOpen = true;
    },
    closeFileModal(state) {
      state.isFileModalOpen = false;
      state.isFileLoading = false;
    },
  },
});

export default importRoutesSlice;
