import { createGlobalStyle, css } from "styled-components";

import "./fonts/fonts.css";

const sizes = {
  xxl: 1600,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 480,
};

export const devices = {
  xxl: `screen and (min-width: ${sizes.xxl}px)`,
  xl: `screen and (min-width: ${sizes.xl}px)`,
  lg: `screen and (min-width: ${sizes.lg}px)`,
  md: `screen and (min-width: ${sizes.md}px)`,
  sm: `screen and (min-width: ${sizes.sm}px)`,
  xs: `screen and (min-width: ${sizes.xs}px)`,
};

const GlobalStyle = createGlobalStyle<{ host: "WEB" | "BITRIX" }>`

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: "Open Sans";

    --color-gray-50: #F9F9F9;
    --color-gray-100: #F2F2F2;
    --color-gray-200: #E8E8E8;
    --color-gray-300: #D8D8D8;
    --color-gray-400: #B4B4B4;
    --color-gray-500: #949494;
    --color-gray-600: #6C6C6C;
    --color-gray-700:#595959;
    --color-gray-800: #3A3A3A;
    --color-gray-900: #1A1A1A;

    --color-green-50: #F5FDEB;
    --color-green-100: #DDF5ED;
    --color-green-500: #83EA67;
    --color-green-600: #5CC94B;
    --color-green-700: #58B07A;
    --color-green-800: #208721;
    --color-green-900: #13701B;


    --color-blue-100: #afd3fc;
    --color-blue-700: #3783ee;
    --color-blue-800: #4d99f0;
    --color-blue-900: #67aaf1;

    --color-main-100: ${(props) => (props.host === "WEB" ? css`var(--color-green-100)` : css`var(--color-blue-100)`)};
    --color-main-700: ${(props) => (props.host === "WEB" ? css`var(--color-green-700)` : css`var(--color-blue-700)`)};
    --color-main-800: ${(props) => (props.host === "WEB" ? css`var(--color-green-800)` : css`var(--color-blue-800)`)};
    --color-main-900: ${(props) => (props.host === "WEB" ? css`var(--color-green-900)` : css`var(--color-blue-900)`)};


    --color-gold-600: #faad14;

    --color-red-50: #FFEDE6;
    --color-red-200: #FCAA97;
    --color-red-300: #F87163;
    --color-red-500: #E90015;
    --color-red-600: #F2271C;


    --antd-wave-shadow-color: ${(props) => (props.host === "WEB" ? css`var(--color-green-700)` : css`var(--color-blue-700)`)};

    --color-SecondHeader: #A4A4A4;
    --color-DarkHeader: #2D3747;

  }

  #root {
    background: #FAFBFB;
    min-height: 100vh;
    display: flex;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  a {
    color: var(--color-gray-900);

    &:hover{
      color: var(--color-main-700);
    }

    &:active {
      color: var(--color-main-700);
    }
  }

  .ant-modal-mask {
    background: rgba(26, 26, 26, 0.5);
  }

  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-close-x {
    font-size: 22px;
    width: 88px;
    height: 88px;
    line-height: 88px;
  }

  .ant-input {
    border: 1px solid var(--color-gray-300);
    border-radius: 8px;
    padding: 9px 14px;
    color: var(--color-gray-800);
    font-size: 16px;
    line-height: 24px;

    ::placeholder {
      color: var(--color-gray-400);
    }

    &:hover {
      border-color: var(--color-green-700);
    }

    &:focus {
      border-color: var(--color-green-700);
      box-shadow: none;
    }
  }

   .ant-spin-text {
    padding-top: 8px;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-main-700);
  }

  .ant-input-status-error {
    border-color: var(--color-red-500);
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
   .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus{
    border-color: var(--color-red-500);
    box-shadow: none;
  }

  /* .ant-form-item {
    margin: 0 0 32px;
  } */

  .ant-form-item-explain {
    font-size: 12px;
    line-height: 16px;
  }

  .ant-form-item-explain-error {
    padding-top: 2px;
    color: #FF4C4C;
  }

  .ant-input-affix-wrapper {
    border: 1px solid var(--color-gray-300);
    border-radius: 8px;
    padding: 9px 14px;
    font-size: 16px;

    .ant-input {
      border-radius: 0;
    }

    &:not(.ant-input-affix-wrapper-disabled){
      &:hover {
        border-color: var(--color-green-700);
      }
    }
  }

  .ant-input-affix-wrapper {
    box-shadow: none;
  }

  .ant-input-affix-wrapper-focused {
    border-color: var(--color-green-700);
  }

  

  .ant-btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    padding: 9px 16px;
    height: 44px;
    border-radius: 6px;

    color: var(--color-gray-900);
    border-color: var(--color-gray-300);

    &:hover {
      color: var(--color-gray-900);
      border-color: var(--color-gray-700);
    }

    &:active {
      color: var(--color-gray-900);
      border-color: var(--color-gray-700);
    }

    &:focus {
      color: var(--color-gray-900);
      border-color: var(--color-gray-700);
    }
  }

  .ant-btn-primary {
    background: var(--color-main-700);
    color: #F7F7FC;
    border-color: var(--color-main-700);
    border-radius: 8px;
    font-size: 16px;

    &:hover {
      background: var(--color-main-800);
      border-color: var(--color-main-800);
      color: #F7F7FC;
    }

    &:focus {
      background: var(--color-main-800);
      border-color: var(--color-main-800);
      color: #F7F7FC;
    }

    &:active {
      background: var(--color-main-900);
      border-color: var(--color-main-900);
      color: #F7F7FC;
    }
  }

  .ant-btn-primary[disabled] {
    opacity: 0.5;
    background: var(--color-main-700);
    color: #F7F7FC;
    border-color: var(--color-main-700);

    &:hover {
      opacity: 0.5;
      background: var(--color-main-700);
      color: #F7F7FC;
      border-color: var(--color-main-700);
    }
  }

  /* .ant-btn-loading>span {
    display: none;
  }

  .ant-btn-loading {
    .ant-btn-loading-icon {
      display: inline-block;
    }
  } */

  .ant-btn-text {
    font-size: 14px;
    line-height: 19px;
    color: var(--color-main-700);
    padding: 0;
    border: none;
    height: auto;

    &:hover {
      color: var(--color-main-800);
      background: transparent;
    }

    &:active {
      color: var(--color-main-900);
      background: transparent;
    }

    &:focus {
      color: var(--color-main-900);
      background: transparent;
    }
  }

  .ant-btn-icon-only {
    width: 44px;
    height: 44px;
    padding: 2.4px 0;
  }

  .ant-btn-icon-only>*{
    font-size: 20px;
  }

  .ant-btn-icon-only.ant-btn-sm {
    width: 33px;
    height: 33px;
    border-radius: 8px;
  }

  .ant-btn-text[disabled],
     .ant-btn-text[disabled]:active,
      .ant-btn-text[disabled]:focus,
       .ant-btn-text[disabled]:hover {
      color: var(--color-gray-400);
    }
  
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  .ant-select-dropdown-empty {
    height: 48px;
    display: flex;
    align-items: center;
  }

  .ant-select-dropdown-loading {
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-select-dropdown-hidden {
    display: none
  }

  /* .ant-form-item-explain-error {
    margin-top: 4px;
  } */

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border-color: var(--color-gray-300);
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--color-green-700);
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--color-green-700);
    box-shadow: none;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 12px;
    height: 44px;
  }

  .ant-select-selection-item {
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .ant-select-dropdown {
    border-radius: 8px;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: var(--color-gray-100);
  }

  .ant-select-item {
    color: var(--color-gray-800);
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: var(--color-gray-900);
    background-color: var(--color-gray-100);
  }
  

  .ant-select-selector {
    min-height: 44px;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: var(--color-green-700);
  }

  .ant-picker {
    height: 44px;
    border-radius: 8px;
    padding: 4px 12px;
  }

  .ant-picker-focused, .ant-picker:hover {
    border-color: var(--color-green-700);
  }

  .ant-picker-focused{
    box-shadow: none;
  }

  .ant-picker-input>input{
    color: var(--color-gray-800);
  }

  .ant-picker-input-placeholder>input{
    color: #bfbfbf;
  }

  .ant-divider {
    border-color: var(--color-gray-200)
  }

  .ant-checkbox-wrapper {
    font-size: 14px;
    color: var(--color-gray-700);
  }

  .ant-spin {
    color: var(--color-gray-400);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-main-700);
    border-color: var(--color-main-700);
  }

  .ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--color-main-700);
  }

  .ant-checkbox-checked:after {
    border-color: var(--color-main-700);
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border-color: var(--color-green-700);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: var(--color-green-700);
  }

  .ant-tooltip-arrow {
    display: none;
  }

  .warning-tooltip .ant-tooltip-arrow {
    display: block;
  }

  .ant-select-selection-placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--color-gray-400);
    display: flex;
    align-items: center;
  }

  .ant-tabs {
    color: var(--color-gray-700);
  }

  .ant-tabs-tab-btn:active, .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:active, .ant-tabs-tab-remove:focus {
    color: var(--color-gray-700);
  }

  .ant-tabs-nav {
    margin: 0!important;
  }

  .ant-tabs-tab {
    /* font-size: 16px;
    line-height: 24px; */

    &:hover {
      color: var(--color-main-800);
    }
  }

  .ant-tabs-nav-add {
    /* padding: 10px 14px!important; */
    &:hover {
      color: var(--color-main-800)!important;
    }
    &:active {
      color: var(--color-main-800)!important;
    }
    &:focus {
      color: var(--color-main-800)!important;
    }
  }

  .ant-tabs-tab-disabled {
    &:hover {
      color: var(--color-gray-700);
    }
  }

  .ant-tabs-tab-active {
    //font-weight: 700;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-main-700);
  }

  .ant-tabs-ink-bar {
    background: var(--color-main-700);
    /* height: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; */
  }

  .ant-input-textarea-show-count:after {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
  }

  a[href^="http://maps.google.com/maps"]{display:none !important}
  a[href^="https://maps.google.com/maps"]{display:none !important}

  .gmnoprint a, .gmnoprint span, .gm-style-cc {
      display:none;
  }
  .gmnoprint div {
      background:none !important;
  }

  .ant-radio-inner {
    border-width: 2px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: var(--color-main-700);
  }

  .ant-radio-inner:after {
    background-color: var(--color-main-700);
  }

  .ant-radio-input:focus+.ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner {
    border-color: var(--color-main-700);
  }

  .ant-radio-input:focus+.ant-radio-inner {
    box-shadow: none;
  }

  .ant-radio-checked:after {
    border-color: var(--color-main-700);
  }

  

  .ant-pagination-item {
    color: var(--color-gray-900);
    border-color: var(--color-gray-300);
    &:hover {
      border-color: var(--color-main-700);
      a {
        color: var(--color-main-700);
      }
    }
  }

  .ant-pagination-item-active {
    border-color: var(--color-main-700);

    a {
        color: var(--color-main-700);
      }
  }

  .ant-pagination-next:focus-visible .ant-pagination-item-link, 
  .ant-pagination-next:hover .ant-pagination-item-link, 
  .ant-pagination-prev:focus-visible .ant-pagination-item-link, 
  .ant-pagination-prev:hover .ant-pagination-item-link {
    color: var(--color-main-700);
    border-color: var(--color-main-700);
  }

  .ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link {
    color: var(--color-gray-300);
    border-color: var(--color-gray-300);
  }

  .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: var(--color-gray-300);
    border-color: var(--color-gray-300);
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
   .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--color-green-700);
   }

   .ant-pagination-total-text {
    margin-right: 16px;
    font-size: 14px;
    color: var(--color-gray-900);
   }

   .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: var(--color-main-700);
   }

   .ant-table-thead>tr {
    min-height: 48px;
    height: 48px;
   }

   .ant-table-thead>tr>th {
    background: var(--color-main-700);
    color: white;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: var(--color-main-700);
  }

   .ant-table-tbody>tr>td {
    background: #ffffff;
   }

   .ant-table-thead th.ant-table-column-sort {
    background: var(--color-main-700);
   }

   /* .ant-table-tbody>tr.ant-table-row-selected>td {
    background: #ffffff;
   }

   .ant-table-tbody>tr.ant-table-row-selected:hover>td {
    background: #fafafa;
   } */

    .ant-table-row-expand-icon:hover, .ant-table-row-expand-icon:active, .ant-table-row-expand-icon:focus {
      color: var(--color-main-700);
    }

    

   .ant-tooltip {
    max-width: 300px;
   }

   .ant-tooltip-inner {
    background: rgba(0, 0, 0, 0.75);
   }

   .ant-picker-range .ant-picker-active-bar {
    background: var(--color-green-700);
   }

   /* .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
   } */

   .ant-table-column-sorter {
    color: var(--color-gray-400);
   }

   .ant-table-column-sorters:hover .ant-table-column-sorter {
    color: white;
   }

   .ant-table-column-sorter-down.active, .ant-table-column-sorter-up.active {
    color: white;
   }

   .templates-table-row td {
    padding-left:0 !important;
   }

   .templates-table-row td:last-child {
    padding-right:0;
   }

   .templates-table-row td:nth-child(2):hover {
    cursor: pointer;
    color: var(--color-green-700);
   }

   .template-name-active {
    color: var(--color-green-700);
   }
   
   /* .ant-tag {
    padding: 4px 24px;
    border-radius: 12px;
    font-size: 12px;
    line-height: 16px;
   } */

   .ant-message-notice-content {
    border: 1px solid transparent;
    padding: 16px;
   }

   .ant-message-notice-success {
      .ant-message-notice-content {
        background: var(--color-green-50);
        border-color: var(--color-green-500);
        color: var(--color-green-900);
      }
   }

   .ant-message-notice-error {
      .ant-message-notice-content {
        background: var(--color-red-50);
        border-color: var(--color-red-200);
        color: var(--color-red-500);
      }
   }

   /* .ant-popover {
      border-radius: 8px;
     .ant-popover-arrow {
      display: none;
     }

     .ant-popover-content {
      border-radius: 8px;
     }

     .ant-popover-inner {
      border-radius: 8px;
     }

     .ant-popover-inner-content {
      border-radius: 8px;
      padding: 4px 0;
     }

     ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      padding: 10px 12px;
      cursor: pointer;

      &:hover {
        background: #F5F5F5;
        transition: all .3s cubic-bezier(.645,.045,.355,1);
      }
    }
   } */

   .ant-popover-placement-bottom {
    width: 150px;
    padding-top: 0;
   }
   
   .ant-table-scroll-position-right .ant-table-fixed-right {
    box-shadow: none !important;
  }

  .ant-table-layout-fixed table {
    table-layout: auto;
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: var(--color-main-700);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: var(--color-main-700);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--color-main-700);
  border-color: var(--color-main-700);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
  background-color: var(--color-main-700);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--color-main-700);
  border-color: var(--color-main-700);
}

.ant-radio-button-wrapper:hover {
  color: var(--color-main-700);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: var(--color-main-700);
  border-color: var(--color-main-700);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active:before {
  background-color: var(--color-main-700);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover:before {
  background-color: var(--color-main-700);
}

@media print {
  .print-padding {
    padding: 10px;
  }
}

.highlighted-row .ant-table-cell {
  background: #FFF5F0 !important;
}

.application-calculation-table-application-service-cell {
  min-width: 350px!important;
}

.ant-card {
  box-shadow: 0px 4px 10px 0px #b9dcc74d;
  border-radius: 10px;
}

`;

export default GlobalStyle;
