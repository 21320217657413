import { DatePicker, Input, Select } from "antd";

import LocationPicker from "../LocationPicker";
import RangeNumberInput from "../RangeNumberInput";

import withFormLabel from "./withFormLabel";

const { Password, TextArea } = Input;

export const RangePickerWithFormLabel = withFormLabel(DatePicker.RangePicker);
export const LocationPickerWithFormLabel = withFormLabel(LocationPicker);
export const BaseInputWithFormLabel = withFormLabel(Input);
export const RangeNumberInputWithFormLabel = withFormLabel(RangeNumberInput);
export const SelectWithFormLabel = withFormLabel(Select);
export const TextAreaWithFormLabel = withFormLabel(TextArea);
export const PasswordWithFormLabel = withFormLabel(Password);
export const DatePickerWithFormLabel = withFormLabel(DatePicker);
