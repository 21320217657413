import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import UserProfileModel from "../../../../../../viewModels/User/UserProfileModel";

import { IClientProfilePageState } from "./types";

const initialState: IClientProfilePageState = {
  loading: true,
  loadingData: true,
  clientProfile: undefined!,
  isUpdateClientProfileLoading: undefined!,
};

const clientProfilePageSlice = createSlice({
  name: "clientProfile",
  initialState,
  reducers: {
    loadClientProfile(state) {
      state.loadingData = true;
    },
    loadClientProfileSuccess(state, action: PayloadAction<UserProfileModel>) {
      state.loading = false;
      state.loadingData = false;
      state.clientProfile = action.payload;
    },
    loadClientProfileError(state) {
      state.loading = false;
      state.loadingData = false;
    },
    // eslint-disable-next-line no-unused-vars
    updateClientProfile(state, action: PayloadAction<UserProfileModel>) {
      state.isUpdateClientProfileLoading = true;
    },
    updateClientProfileSuccess(state, action: PayloadAction<UserProfileModel>) {
      state.isUpdateClientProfileLoading = false;
      state.clientProfile = action.payload;
    },
    updateClientProfileError(state) {
      state.isUpdateClientProfileLoading = false;
    },
  },
});

export default clientProfilePageSlice;
