import { call, put, race, take } from "redux-saga/effects";

import { RequestTypes } from "../request/constants";

import { ApiActions } from "./actions";

import { requestLifecycleFromType } from "./utils";

import { IApiRequest, ApiRequestAction, ApiCallResult } from "../types";

import { SERVER_ADDRESSES, SERVER_APPLICATION_NAMES } from "../../config/serverAddressesConfig";

/**
 *
 * @export
 *
 * @class ApiService
 * This class creates standart api actions.
 *
 */
export class ApiService {
  /**
   *
   * @public
   *
   * @param {string} serverAddress - address of API
   *
   * @memberOf ApiService
   */
  public serverAddress: string;

  /**
   *
   * @public
   *
   * @param {RequestTypes} requestType - type of APIs connection
   *
   * @memberOf ApiService
   */
  public requestType: RequestTypes;

  /**
   *
   * @private
   *
   * @param {string} applicationName
   *
   * @memberOf ApiService
   */
  private applicationName: string;

  /**
   * Creates an instance of ApiService.
   *
   * @param {string} serverAddress - address of API.
   * @param {RequestTypes} requestType - type of APIs connection.
   * @param {string} applicationName
   *
   */
  constructor(serverAddress: string, requestType: RequestTypes, applicationName: string) {
    this.serverAddress = serverAddress;
    this.requestType = requestType;
    this.applicationName = applicationName;
  }

  /**
   * Creates GET API action.
   *
   * @private
   *
   * @param {string} controller - service name to call
   * @param {string | number} id - id of resource to get (goes to params of action)
   * @param {unknown} query - extra paramaters. Will go to query
   *
   * @return {ApiRequestAction} GET API request action.
   *
   * @memberOf ApiService
   *
   */
  private GetAction(controller: string, id?: string | number, query?: unknown): ApiRequestAction {
    const payload: Partial<IApiRequest> = {
      query,
    };

    this.SetIdParamToPayload(payload, id);

    return ApiActions.apiAction(this.applicationName, controller, "GET")(this.serverAddress, payload as IApiRequest, this.requestType);
  }

  public *Get(controller: string, id?: string | number, query?: unknown): unknown {
    const action = this.GetAction(controller, id, query);
    return yield call(this.MakeRequest, action);
  }

  /**
   * Creates POST API action.
   *
   * @private
   *
   * @param {string} controller - service name to call
   * @param {unknown} body - new data for resource
   * @param {string | number} id - id of resource to update (goes to params of action)
   * @param {unknown} query - will go to query
   * @param {unknown} headers - will go to headers
   *
   * @return {ApiRequestAction} POST API request action.
   *
   * @memberOf ApiService
   */

  private PostAction(controller: string, body: unknown, id?: string | number, query?: unknown, headers?: unknown): ApiRequestAction {
    const payload: Partial<IApiRequest> = {
      body,
      query,
      headers,
    };

    this.SetIdParamToPayload(payload, id);

    return ApiActions.apiAction(this.applicationName, controller, "POST")(this.serverAddress, payload as IApiRequest, this.requestType);
  }

  public *Post(controller: string, body: unknown, id?: string | number, query?: unknown, headers?: unknown): unknown {
    const action = this.PostAction(controller, body, id, query, headers);
    return yield call(this.MakeRequest, action);
  }

  public PostMultipartFormAction(controller: string, body: any, id?: string | number, query?: any, headers?: any): ApiRequestAction {
    if (!headers) {
      headers = {};
    }

    headers["content-type"] = "multipart/form-data";

    return this.PostAction(controller, body, id, query, headers);
  }

  public *PostMultipartForm(controller: string, body: any, id?: string | number, query?: any, headers?: any) {
    const action = this.PostMultipartFormAction(controller, body, id, query, headers);
    const apiCallResult: ApiCallResult = yield call(this.MakeRequest, action);
    return apiCallResult;
  }

  /**
   * Creates PATCH API action.
   *
   * @private
   *
   * @param {string} controller - service name to call
   * @param {string | number} id - id of resource to update (goes to params of action)
   * @param {unknown} body - new data for resource
   * @param {unknown} query - will go to query
   * @param {unknown} headers - will go to headers
   *
   * @return {ApiRequestAction} PATCH API request action.
   *
   * @memberOf ApiService
   */

  private PatchAction(controller: string, id: string | number, body: unknown, query?: unknown, headers?: unknown): ApiRequestAction {
    const payload: Partial<IApiRequest> = {
      body,
      query,
      headers,
    };

    this.SetIdParamToPayload(payload, id);

    return ApiActions.apiAction(this.applicationName, controller, "PATCH")(this.serverAddress, payload as IApiRequest, this.requestType);
  }

  public *Patch(controller: string, id: string | number, body?: unknown, query?: unknown, headers?: unknown): unknown {
    const action = this.PatchAction(controller, id, body, query, headers);
    return yield call(this.MakeRequest, action);
  }

  /**
   * Creates PUT API action.
   *
   * @private
   *
   * @param {string} controller - service name to call
   * @param {string | number} id - id of resource to update (goes to params of action)
   * @param {unknown} body - new data for resource
   * @param {unknown} query - will go to query
   *
   * @return {ApiRequestAction} PATCH API request action.
   *
   * @memberOf ApiService
   */

  public PutAction(controller: string, id?: string | number, body?: unknown, query?: unknown): ApiRequestAction {
    const payload: Partial<IApiRequest> = {
      body,
      query,
    };

    this.SetIdParamToPayload(payload, id);

    return ApiActions.apiAction(this.applicationName, controller, "PUT")(this.serverAddress, payload as IApiRequest, this.requestType);
  }

  public *Put(controller: string, id?: string | number, body?: unknown, query?: unknown): unknown {
    const action = this.PutAction(controller, id, body, query);
    return yield call(this.MakeRequest, action);
  }

  /**
   * Creates DELETE API action.
   *
   * @private
   *
   * @param {string} controller
   * @param {string | number} id - id of resource to delete (goes to params of action)
   * @param {unknown} query - will go to query
   *
   * @return {ApiRequestAction} PATCH API request action.
   *
   * @memberOf ApiService
   */

  private DeleteAction(controller: string, id?: string | number, query?: unknown): ApiRequestAction {
    const payload: Partial<IApiRequest> = {
      query,
    };

    this.SetIdParamToPayload(payload, id);

    return ApiActions.apiAction(this.applicationName, controller, "DELETE")(this.serverAddress, payload as IApiRequest, this.requestType);
  }

  public *Delete(controller: string, id?: string | number, query?: unknown): unknown {
    const action = this.DeleteAction(controller, id, query);
    return yield call(this.MakeRequest, action);
  }

  /**
   * Make created action
   *
   * @private
   *
   * @param {ApiRequestAction} requestAction
   *
   * @return {unknown} Result of API call
   *
   * @memberOf ApiService
   */

  private *MakeRequest(requestAction: ApiRequestAction) {
    const lifecycle = requestLifecycleFromType(requestAction.type);
    yield put(requestAction);
    const { success, reject } = yield race({
      success: take(lifecycle.RESOLVED),
      reject: take(lifecycle.REJECTED),
    });

    const result: ApiCallResult = {
      success,
      reject,
    };

    return result;
  }

  private SetIdParamToPayload(payload: Partial<IApiRequest>, id?: string | number): void {
    if (id) {
      const paramsMap = new Map<string, unknown>();
      paramsMap.set("id", id);
      payload.params = paramsMap;
    }
  }
}

export const githubApiService = new ApiService(SERVER_ADDRESSES.GITHUB_API, "HTTP", SERVER_APPLICATION_NAMES.GITHUB_API);

export const authApiService = new ApiService(SERVER_ADDRESSES.AUTH_API, "HTTP", SERVER_APPLICATION_NAMES.AUTH_API);

export const dictionaryApiService = new ApiService(SERVER_ADDRESSES.DICTIONARY_API, "HTTP", SERVER_APPLICATION_NAMES.DICTIONARY_API);

export const routeApiService = new ApiService(SERVER_ADDRESSES.ROUTE_API, "HTTP", SERVER_APPLICATION_NAMES.ROUTE_API);

export const backOfficeApiService = new ApiService(SERVER_ADDRESSES.BACK_OFFICE_API, "HTTP", SERVER_APPLICATION_NAMES.BACK_OFFICE_API);

export const chatApiService = new ApiService(SERVER_ADDRESSES.CHAT_API, "HTTP", SERVER_APPLICATION_NAMES.CHAT_API);

export const docApiService = new ApiService(SERVER_ADDRESSES.DOC_API, "HTTP", SERVER_APPLICATION_NAMES.DOC_API);

export const notificationApiService = new ApiService(SERVER_ADDRESSES.NOTIFICATION_API, "HTTP", SERVER_APPLICATION_NAMES.NOTIFICATION_API);

export const auditApiService = new ApiService(SERVER_ADDRESSES.AUDIT_API, "HTTP", SERVER_APPLICATION_NAMES.AUDIT_API);
