export const AUTH_API_CONTROLLER_END_POINTS = {
  CONTROLLER_PATH: "user",
  CURRENT_PATH: "/current",
  LOGIN_PATH: "/login",
  REGISTER_PATH: "/register",
  PASSWORD_RECOVERY_PATH: "/password/recovery",
  LOGOUT_PATH: "/logout",
  DETAILED_INFO_PATH: "/detailed-info",
  CHANGE_PASSWORD_PATH: "/password-update",
  FILTER_PATH: "/filter",
  ROLES_FILTER_PATH: "/roles/filter",
  ROLES_PATH: "/roles",
  ROLES_BASIC_INFO_PATH: "/roles/basic-info",
  INTERNAL_USER_RESET_PASSWORD_PATH: "/reset-password",
  USER_BASIC_INFO: "/basic-info",
  LOGIN_BITRIX: "/login/bitrix",
};
