import { useEffect, useRef, useState } from "react";

// TODO add normal library for forking with timer
function getReturnValues(secondsLeft: number) {
  const minutes = Math.floor(secondsLeft / 60);
  const seconds = Math.floor(secondsLeft - 60 * minutes);

  return {
    secondsLeft,
    minutesNmber: minutes,
    secondsNumber: seconds,
    minutes: `0${minutes}`.slice(-2),
    seconds: `0${seconds}`.slice(-2),
  };
}

export default function useTimer(seconds: number, version?: number) {
  const [secondsLeft, setSecondsLeft] = useState(seconds);
  const intervalId = useRef<NodeJS.Timer>();

  useEffect(() => {
    setSecondsLeft(seconds);
    intervalId.current = setInterval(() => {
      setSecondsLeft((s) => s - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, [seconds, version]);

  useEffect(() => {
    if (intervalId?.current && secondsLeft === 0) {
      clearInterval(intervalId.current);
    }
  }, [secondsLeft]);

  return getReturnValues(secondsLeft);
}
