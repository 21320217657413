import qs from "qs";

import { SERVER_ADDRESSES } from "../../../config/serverAddressesConfig";

import { LocationDto } from "../../../dto/Location/LocationDto";
import { DICTIONARY_API_LOCATIONS_CONTROLLER_END_POINTS } from "../../../config/apiEndPoints/DictionaryApiEndsPoints/LocationsController";
import { DictionariesLocationDto } from "../../../dto/Dictionaries/DictionariesLocationDto";

const locationPath = `${SERVER_ADDRESSES.DICTIONARY_API}/${DICTIONARY_API_LOCATIONS_CONTROLLER_END_POINTS.CONTROLLER_PATH}${DICTIONARY_API_LOCATIONS_CONTROLLER_END_POINTS.BASIC_INFO}`;
const unknownLocationPath = `${SERVER_ADDRESSES.DICTIONARY_API}/${DICTIONARY_API_LOCATIONS_CONTROLLER_END_POINTS.CONTROLLER_PATH}${DICTIONARY_API_LOCATIONS_CONTROLLER_END_POINTS.UNKNOWN_INFO}`;

export async function fetchLocations(abortController: AbortController, pattern: string): Promise<Array<LocationDto>> {
  const queryStr = qs.stringify({ pattern }, { addQueryPrefix: true });
  const response = await fetch(`${locationPath}${queryStr}`, {
    signal: abortController.signal,
  });
  return response.json();
}

export async function createLocation(name: string): Promise<LocationDto> {
  let response = await fetch(unknownLocationPath, {
    method: "POST",
    headers: { Accept: "application/json", "content-type": "application/json" },
    body: JSON.stringify({
      name,
      waypoint: false,
      borderCrossing: false,
    }),
  });
  const location: DictionariesLocationDto = await response.json();

  response = await fetch(`${locationPath}/${location.id}`);
  const locations: Array<LocationDto> = await response.json();

  return locations[0];
}
