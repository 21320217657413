import { createAction } from "@reduxjs/toolkit";

import { LoginInputModel } from "../viewModels/Auth/LoginInputModel";
import { RegisterInputModel } from "../viewModels/Auth/RegisterInputModel";
import { RestorePasswordInputModel } from "../viewModels/Auth/RestorePasswordInputModel";
import { FormErrorModel } from "../viewModels/General/ErrorModel";
import { UserInfoModel } from "../viewModels/User/UserInfoModel";

export const LOGIN = "Auth/LOGIN";
export const LOGIN_SUCCESS = "Auth/LOGIN_SUCCESS";
export const LOGIN_ERROR = "Auth/LOGIN_ERROR";

export const LOGIST_LOGIN = "Auth/LOGIST_LOGIN";
export const LOGIST_LOGIN_SUCCESS = "Auth/LOGIST_LOGIN_SUCCESS";
export const LOGIST_LOGIN_ERROR = "Auth/LOGIST_LOGIN_ERROR";

export const RESTORE_PASSWORD = "Auth/RESTORE_PASSWORD";
export const RESTORE_PASSWORD_SUCCESS = "Auth/RESTORE_PASSWORD_SUCCESS";
export const RESTORE_PASSWORD_ERROR = "Auth/RESTORE_PASSWORD_ERROR";

export const RESEND_RESTORE_PASSWORD = "Auth/RESEND_RESTORE_PASSWORD";
export const RESEND_RESTORE_PASSWORD_SUCCESS = "Auth/RESEND_RESTORE_PASSWORD_SUCCESS";
export const RESEND_RESTORE_PASSWORD_ERROR = "Auth/RESEND_RESTORE_PASSWORD_ERROR";

export const REGISTER = "Auth/REGISTER";
export const REGISTER_SUCCESS = "Auth/REGISTER_SUCCESS";
export const REGISTER_ERROR = "Auth/REGISTER_ERROR";

export const RESEND_REGISTER_EMAIL = "Auth/RESEND_REGISTER_EMAIL";
export const RESEND_REGISTER_EMAIL_SUCCESS = "Auth/RESEND_REGISTER_EMAIL_SUCCESS";
export const RESEND_REGISTER_EMAIL_ERROR = "Auth/RESEND_REGISTER_EMAIL_ERROR";

export const LOGOUT = "Auth/LOGOUT";
export const LOGOUT_SUCCESS = "Auth/LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "Auth/LOGOUT_ERROR";

export const AuthActions = {
  login: createAction<LoginInputModel>(LOGIN),
  loginSuccess: createAction<UserInfoModel>(LOGIN_SUCCESS),
  loginError: createAction<Array<FormErrorModel>>(LOGIN_ERROR),

  logistLogin: createAction<LoginInputModel>(LOGIST_LOGIN),
  logistLoginSuccess: createAction<UserInfoModel>(LOGIST_LOGIN_SUCCESS),
  logistLoginError: createAction<Array<FormErrorModel>>(LOGIST_LOGIN_ERROR),

  restorePassword: createAction<RestorePasswordInputModel>(RESTORE_PASSWORD),
  restorePasswordSuccess: createAction<string>(RESTORE_PASSWORD_SUCCESS),
  restorePasswordError: createAction<Array<FormErrorModel>>(RESTORE_PASSWORD_ERROR),

  resendRestorePassword: createAction(RESEND_RESTORE_PASSWORD),
  resendRestorePasswordSuccess: createAction(RESEND_RESTORE_PASSWORD_SUCCESS),
  resendRestorePasswordError: createAction(RESEND_RESTORE_PASSWORD_ERROR),

  register: createAction<RegisterInputModel>(REGISTER),
  registerSuccess: createAction<string>(REGISTER_SUCCESS),
  registerError: createAction<Array<FormErrorModel>>(REGISTER_ERROR),

  resendRegisterEmail: createAction(RESEND_REGISTER_EMAIL),
  resendRegisterEmailSuccess: createAction(RESEND_REGISTER_EMAIL_SUCCESS),
  resendRegisterEmailError: createAction(RESEND_REGISTER_EMAIL_ERROR),

  logout: createAction(LOGOUT),
  logoutSuccess: createAction(LOGOUT_SUCCESS),
  logoutError: createAction(LOGOUT_ERROR),
};
