import { take, fork, put, select, call } from "redux-saga/effects";
import { makeSelectEmailForResendRestorePassword } from "../../../containers/AuthModal/selectors";

import { ApiCallResult } from "../../../api/types";

import { AuthActions, RESTORE_PASSWORD, RESEND_RESTORE_PASSWORD } from "../../actions";
import { authHttpService } from "../../apiService";

function* restorePasswordWatcher() {
  while (true) {
    const { payload }: ReturnType<typeof AuthActions.restorePassword> = yield take(RESTORE_PASSWORD);
    const { email } = payload;
    const { success, reject }: ApiCallResult = yield call([authHttpService, authHttpService.RecoveryPassword], email);

    if (success) {
      yield put(AuthActions.restorePasswordSuccess(email));
    }

    if (reject) {
      yield put(AuthActions.restorePasswordError(reject.payload.errors!));
    }
  }
}

function* resendRestorePasswordWatcher() {
  while (true) {
    yield take(RESEND_RESTORE_PASSWORD);
    const email: string = yield select(makeSelectEmailForResendRestorePassword());
    const { success, reject }: ApiCallResult = yield call([authHttpService, authHttpService.RecoveryPassword], email);

    if (success) {
      yield put(AuthActions.resendRestorePasswordSuccess());
    }

    if (reject) {
      yield put(AuthActions.resendRestorePasswordError());
    }
  }
}

export function* restorePasswordFlow() {
  yield fork(restorePasswordWatcher);
  yield fork(resendRestorePasswordWatcher);
}
