const { protocol } = window.location;

export const GITHUB_API_CONFIG = {
  API_SERVER_SCHEMA: protocol,
  API_SERVER_HOST: process.env.REACT_APP_GITHUB_SERVER_API_HOST,
  API_NAME: process.env.REACT_APP_GITHUB_SERVER_API_APP_NAME!,
};

export const AUTH_API_CONFIG = {
  API_SERVER_SCHEMA: protocol,
  API_SERVER_HOST: process.env.REACT_APP_AUTH_API_HOST,
  API_NAME: process.env.REACT_APP_AUTH_API_APP_NAME!,
};

export const DICTIONARY_API_CONFIG = {
  API_SERVER_SCHEMA: protocol,
  API_SERVER_HOST: process.env.REACT_APP_DICTIONARY_API_HOST,
  API_NAME: process.env.REACT_APP_DICTIONARY_API_APP_NAME!,
};

export const ROUTE_API_CONFIG = {
  API_SERVER_SCHEMA: protocol,
  API_SERVER_HOST: process.env.REACT_APP_ROUTE_API_HOST,
  API_NAME: process.env.REACT_APP_ROUTE_API_APP_NAME!,
};

export const BACK_OFFICE_API_CONFIG = {
  API_SERVER_SCHEMA: protocol,
  API_SERVER_HOST: process.env.REACT_APP_BACK_OFFICE_API_HOST,
  API_NAME: process.env.REACT_APP_BACK_OFFICE_API_APP_NAME!,
};

export const CHAT_API_CONFIG = {
  API_SERVER_SCHEMA: protocol,
  API_SERVER_HOST: process.env.REACT_APP_CHAT_API_HOST,
  API_NAME: process.env.REACT_APP_CHAT_API_HOST!,
};

export const DOC_API_CONFIG = {
  API_SERVER_SCHEMA: protocol,
  API_SERVER_HOST: process.env.REACT_APP_DOC_API_HOST,
  API_NAME: process.env.REACT_APP_DOC_API_APP_NAME!,
};

export const NOTIFICATION_API_CONFIG = {
  API_SERVER_SCHEMA: protocol,
  API_SERVER_HOST: process.env.REACT_APP_NOTIFICATION_API_HOST,
  API_NAME: process.env.REACT_APP_NOTIFICATION_API_HOST!,
};

export const AUDIT_API_CONFIG = {
  API_SERVER_SCHEMA: protocol,
  API_SERVER_HOST: process.env.REACT_APP_AUDIT_API_HOST,
  API_NAME: process.env.REACT_APP_AUDIT_API_HOST!,
};
