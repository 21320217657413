import { createAction, nanoid } from "@reduxjs/toolkit";

import { apiType } from "./utils";
import { HttpRequestMethods, RequestTypes } from "../request/constants";

import { IApiRequest, ILifecycle } from "../types";
import { ErrorModel } from "../../viewModels/General/ErrorModel";

export const ApiActions = {
  resolve: (lifecycle: ILifecycle) => createAction<unknown>(lifecycle.RESOLVED),
  reject: (lifecycle: ILifecycle) =>
    createAction(lifecycle.REJECTED, (error: ErrorModel) => ({
      payload: error,
    })),
  pending: (lifecycle: ILifecycle) => createAction(lifecycle.PENDING),
  apiAction: (applicationName: string, controller: string, requestMethod: HttpRequestMethods) => {
    const id = nanoid();
    const type = `${apiType(applicationName, controller, requestMethod)}_${id}`;
    return createAction(type, (server: string, payload: IApiRequest, requestType: RequestTypes) => ({
      payload: {
        ...payload,
        server,
        controller,
        requestMethod,
        requestType,
        requestId: id,
      },
      meta: {
        api: true,
      },
    }));
  },
};
