import { call } from "redux-saga/effects";

import { ApiService, authApiService } from "../../api/actionService/apiService";
import { ApiCallResult } from "../../api/types";

import { AUTH_API_CONTROLLER_END_POINTS } from "../../config/apiEndPoints/AuthApiEndPoints/AuthController";

const authController = AUTH_API_CONTROLLER_END_POINTS.CONTROLLER_PATH;
const loginPath = `${authController}${AUTH_API_CONTROLLER_END_POINTS.LOGIN_PATH}`;
const registerPath = `${authController}${AUTH_API_CONTROLLER_END_POINTS.REGISTER_PATH}`;
const passwordRecoveryPath = `${authController}${AUTH_API_CONTROLLER_END_POINTS.PASSWORD_RECOVERY_PATH}`;
const logoutPath = `${authController}${AUTH_API_CONTROLLER_END_POINTS.LOGOUT_PATH}`;

export class AuthHttpService {
  private authApiService: ApiService;

  constructor(passedAuthApiService: ApiService) {
    this.authApiService = passedAuthApiService;
  }

  public *LoginByCredentials(login: string, password: string) {
    return (yield call([this.authApiService, this.authApiService.Post], loginPath, { login, password })) as ApiCallResult;
  }

  public *Register(login: string, companyName: string, phoneNumber: string, email: string) {
    return (yield call([this.authApiService, this.authApiService.Post], registerPath, {
      login,
      companyName,
      phoneNumber,
      email,
    })) as ApiCallResult;
  }

  public *RecoveryPassword(email: string) {
    return (yield call([this.authApiService, this.authApiService.Post], passwordRecoveryPath, {
      email,
    })) as ApiCallResult;
  }

  public *Logout() {
    return (yield call([this.authApiService, this.authApiService.Get], logoutPath)) as ApiCallResult;
  }
}

export const authHttpService = new AuthHttpService(authApiService);
