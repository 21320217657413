import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SupportedLanguages } from "../../viewModels/Settings/TranslationModel";

const initialState: {
  loading: boolean;
  loadingData: boolean;

  translations: { [key in SupportedLanguages]: { [key: string]: string } };
} = {
  loading: false,
  loadingData: false,

  translations: undefined!,
};

const translationsSlice = createSlice({
  name: "translations",
  initialState,
  reducers: {
    loadTranslations(state) {
      state.loadingData = true;
    },

    setLoadedTranslations(state, action: PayloadAction<{ [key in SupportedLanguages]: { [key: string]: string } }>) {
      state.loadingData = false;
      state.translations = action.payload;
    },

    // eslint-disable-next-line no-unused-vars
    updateTranslations(state, action: PayloadAction<{ [key in SupportedLanguages]: { [key: string]: string } }>) {
      state.loadingData = true;
    },
  },
});

export default translationsSlice;
