import "whatwg-fetch";
import qs from "qs";

import { convertObjectToError } from "./error";

import { IApiRequest } from "../types";

function makePathFromParams(map: Map<any, any>, separator: string): string {
  if (!(map instanceof Map)) {
    throw new Error("Invalid parameter object. Must be Map");
  }

  let mapString = "";

  map.forEach((value, key) => {
    let strToAdd = "";

    if (value instanceof Map) {
      if (typeof key === "object") {
        throw new Error(`Invalid parameter key. Must be plain type ${key}`);
      }

      strToAdd += separator + key;
      strToAdd += makePathFromParams(value, separator);
    } else {
      if (typeof value === "object") {
        throw new Error(`Invalid parameter value. Must be plain type ${key}`);
      }

      strToAdd += separator + value;
    }

    mapString += strToAdd;
  });

  return mapString;
}

export function convertModelToFormData(model: any, form?: FormData, namespace = ""): FormData {
  const formData = form || new FormData();

  Object.keys(model).forEach((propertyName) => {
    if (!Object.prototype.hasOwnProperty.call(model, propertyName) || !model[propertyName]) {
      return;
    }

    const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;

    if (model[propertyName] instanceof Date) {
      formData.append(formKey, model[propertyName].toISOString());
    } else if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element: any, index: number) => {
        const tempFormKey = `${formKey}[${index}]`;
        convertModelToFormData(element, formData, tempFormKey);
      });
    } else if (
      typeof model[propertyName] === "object" &&
      !(model[propertyName] instanceof File) &&
      !(model[propertyName] instanceof Blob)
    ) {
      convertModelToFormData(model[propertyName], formData, formKey);
    } else if (model[propertyName] instanceof File) {
      formData.append(formKey, model[propertyName], encodeURI(model[propertyName].name));
    } else if (model[propertyName] instanceof Blob) {
      // should pass `name` property it as part of blob
      formData.append(formKey, model[propertyName], model[propertyName].name);
    } else {
      formData.append(formKey, model[propertyName].toString());
    }
  });

  return formData;
}

/**
 * Parses the JSON returned by a network request
 */
function parseJSON(response: Response) {
  return response.json().catch(() => ({
    status: response.status,
    code: response.status,
    httpCode: response.status,
  }));
}

/**
 * Checks if a network request came back fine, and throws an error if not
 */
function checkStatus(response: Response): Response | Promise<unknown> {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  // if (response.status === 403 && window.location.pathname !== "/" && window.location.pathname !== "/logist/login") {
  //   window.location.href = "/";
  // }

  return parseJSON(response).then((errorData: unknown) => {
    const error = convertObjectToError(errorData);
    if (!(error as any).httpCode) {
      (error as any).httpCode = response.status;
    }
    throw error;
  });
}

/**
 * Requests a URL, returning a promise
 */
function makeRequest(server: string, url: string, options: RequestInit): Promise<unknown> {
  options.credentials = "include";
  options.mode = "cors";

  const urlToSend = `${server}/${url}`;
  console.warn("HTTP API requested | ", urlToSend, options);
  return fetch(urlToSend, options)
    .then(checkStatus)
    .then((resp: any) => {
      // TODO check why content type is null
      if (
        resp.headers.get("Content-type") !== "application/json" &&
        resp.headers.get("Content-type") !== "application/json;charset=UTF-8" &&
        resp.headers.get("Content-type") !== null
      ) {
        // TODO very bad hack, all non app/json content-types opens in new tab
        const contentDispositionHeader = resp.headers.get("Content-Disposition");
        const parts = contentDispositionHeader!.split(";");
        const filename = decodeURI(parts[1].split(`"`)[1]);
        resp.blob().then((blob: any) => {
          const urlFile = window.URL.createObjectURL(blob);
          const aFile = document.createElement("a");
          aFile.href = urlFile;
          aFile.download = filename;
          aFile.click();
        });
      }
      return parseJSON(resp as Response);
    });
}

export default function request(
  { server, controller, requestMethod, params, query, body, headers }: IApiRequest,
  host: "WEB" | "BITRIX",
): Promise<unknown> {
  let urlString = controller;

  if (params !== undefined && params) {
    urlString += makePathFromParams(params, controller ? "/" : "");
  }

  if (!query) {
    query = {};
  }

  if (host === "BITRIX") {
    query.initiator = "Bitrix";
  }

  const queryStr = qs.stringify(query);
  urlString += `?${queryStr}`;

  const options: RequestInit = {
    method: requestMethod || "GET",
  };

  if (!headers) {
    headers = {
      Accept: "application/json",
      "content-type": "application/json",
    };
  }

  // if (headers["content-type"] === "application/x-www-form-urlencoded") {
  //   if (body) {
  //     const formData = new URLSearchParams(body);
  //     options.body = formData;
  //   }
  // }

  if (headers["content-type"] === "multipart/form-data") {
    if (body) {
      const formData = convertModelToFormData(body);
      options.body = formData;
    }
    delete headers["content-type"];
  }

  if (headers["content-type"] === "application/json") {
    if (body) {
      options.body = JSON.stringify(body);
    }
  }

  // headers.Initiator = host === "BITRIX" ? "Bitrix" : "Web";

  options.headers = headers;

  return makeRequest(server, urlString, options);
}
