function isSerializedError(obj: any): any {
  return obj && typeof obj === "object" && typeof obj.name === "string" && typeof obj.message === "string";
}

function deserializeError(obj: any): Error {
  if (!isSerializedError(obj)) {
    return obj;
  }
  return Object.assign(new Error(), { stack: undefined }, obj);
}

export function convertObjectToError(obj: any): Error | Error[] | { [key: string]: Error } {
  if (obj instanceof Error) {
    return obj;
  }
  if (obj instanceof Array) {
    return obj.map(deserializeError);
  }
  if (typeof obj !== "object") {
    return obj;
  }

  const err = deserializeError(obj);
  if (err !== obj) {
    return err;
  }

  const objErr: { [key: string]: Error } = {};

  Object.keys(obj).forEach((key) => {
    if ({}.hasOwnProperty.call(obj, key)) {
      objErr[key] = deserializeError(obj[key]);
    }
  });

  return objErr;
}

export const ERROR_SUBCODES = {
  TOKEN_EXPIRED_ERROR: 1,
};
