import { take, fork, put, select, call } from "redux-saga/effects";

import { makeSelectEmailForResendRegistration } from "../../../containers/AuthModal/selectors";

import { ApiCallResult } from "../../../api/types";

import { AuthActions, REGISTER, RESEND_REGISTER_EMAIL } from "../../actions";
import { authHttpService } from "../../apiService";

function* registerWatcher() {
  while (true) {
    const { payload }: ReturnType<typeof AuthActions.register> = yield take(REGISTER);

    const { email, companyName, login, phoneNumber } = payload;
    const { success, reject }: ApiCallResult = yield call(
      [authHttpService, authHttpService.Register],
      login,
      companyName,
      phoneNumber,
      email,
    );

    if (success) {
      yield put(AuthActions.registerSuccess(email));
    }

    if (reject) {
      yield put(AuthActions.registerError(reject.payload.errors!));
    }
  }
}

function* resendRegisterEmailWatcher() {
  while (true) {
    yield take(RESEND_REGISTER_EMAIL);
    const email: string = yield select(makeSelectEmailForResendRegistration());

    const { success, reject }: ApiCallResult = yield call([authHttpService, authHttpService.RecoveryPassword], email);

    if (success) {
      yield put(AuthActions.resendRegisterEmailSuccess());
    }

    if (reject) {
      yield put(AuthActions.resendRegisterEmailError());
    }
  }
}

export function* registerFlow() {
  yield fork(registerWatcher);
  yield fork(resendRegisterEmailWatcher);
}
