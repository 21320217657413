import styled, { css } from "styled-components";

export const headingStyles = css`
  font-family: "Helvetica Neue";
  font-weight: 700;
  color: ${({ color }: any) => (color ? `var(--color-${color})` : "var(--color-gray-900)")};
  ${({ nonStandartColor }: any) => nonStandartColor && `color: ${nonStandartColor};`}
  margin: ${({ margin }: any) => margin || "0"};
`;

export interface H1Props {
  color?:
    | "gray-100"
    | "gray-200"
    | "gray-300"
    | "gray-400"
    | "gray-500"
    | "gray-600"
    | "gray-700"
    | "gray-800"
    | "gray-900"
    | "green-700"
    | "SecondHeader"
    | "DarkHeader";
  nonStandartColor?: string;
  margin?: string;
}

const H1 = styled.h1<H1Props>`
  font-size: 48px;
  line-height: 60px;

  ${headingStyles};
`;

export default H1;
