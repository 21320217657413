import { createSelector } from "@reduxjs/toolkit";

import { IAuthModalState } from "./reducer/types";

const selectGlobal = (state: any): IAuthModalState => state.authModal;

const makeSelectCurrentView = () => createSelector(selectGlobal, (authModalState) => authModalState.currentView);

const makeSelectIsLoginLoading = () => createSelector(selectGlobal, (authModalState) => authModalState.isLoginLoading);
const makeSelectLoginFormErrors = () => createSelector(selectGlobal, (authModalState) => authModalState.loginFormErrors);

const makeSelectRestorePasswordFormErrors = () =>
  createSelector(selectGlobal, (authModalState) => authModalState.restorePasswordFormErrors);
const makeSelectIsRestorePasswordLoading = () => createSelector(selectGlobal, (authModalState) => authModalState.isRestorePasswordLoading);

const makeSelectIsRegisterLoading = () => createSelector(selectGlobal, (authModalState) => authModalState.isRegisterLoading);
const makeSelectRegistrationFormErrors = () => createSelector(selectGlobal, (authModalState) => authModalState.registrationFormErrors);

const makeSelectEmailForResendRegistration = () =>
  createSelector(selectGlobal, (authModalState) => authModalState.emailForResendRegistration);

const makeSelectEmailForResendRestorePassword = () =>
  createSelector(selectGlobal, (authModalState) => authModalState.emailForResendRestorePassword);

export {
  makeSelectCurrentView,
  makeSelectIsLoginLoading,
  makeSelectLoginFormErrors,
  makeSelectIsRestorePasswordLoading,
  makeSelectIsRegisterLoading,
  makeSelectRegistrationFormErrors,
  makeSelectEmailForResendRegistration,
  makeSelectEmailForResendRestorePassword,
  makeSelectRestorePasswordFormErrors,
};
