import React from "react";

import { LoadingOutlined } from "@ant-design/icons";

import { Spin } from "antd";

import { Container } from "./styled";

const antIcon = <LoadingOutlined style={{ fontSize: 36, color: "var(--color-main-700)" }} spin />;

type LoadingIndicatorProps = {
  tip?: React.ReactNode;
};

function LoadingIndicator({ tip }: LoadingIndicatorProps) {
  return (
    <Container>
      <Spin indicator={antIcon} tip={tip} />
    </Container>
  );
}

export default React.memo(LoadingIndicator);
