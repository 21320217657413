import { call, put, select, takeEvery } from "redux-saga/effects";

import chooseRequest from "./request/utils";

import { ApiActions } from "./actionService/actions";

import { requestLifecycleFromType } from "./actionService/utils";

import { ApiRequestAction } from "./types";

import { ErrorModel } from "../viewModels/General/ErrorModel";
import { makeSelectHost } from "../containers/App/selectors";

const isApiCall = ({ meta }: any) => meta && meta.api;

function* requestAPI(action: ApiRequestAction) {
  const { payload } = action;

  const host: "WEB" | "BITRIX" = yield select(makeSelectHost());

  const lifecycle = requestLifecycleFromType(action.type);
  yield put(ApiActions.pending(lifecycle)());

  try {
    const request = chooseRequest(payload);
    const result: unknown = yield call(request, payload, host);
    yield put(ApiActions.resolve(lifecycle)(result));
  } catch (error) {
    console.error("Api request failed", error);
    yield put(ApiActions.reject(lifecycle)(error as ErrorModel));
  }
}

function* apiSaga() {
  yield takeEvery(isApiCall, requestAPI);
}

export default apiSaga;
