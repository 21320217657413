import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAuditLogPageState, LoadedAuditLogPayloadType, SortObject } from "./types";

const initialState: IAuditLogPageState = {
  loading: true,
  loadingData: true,

  auditLog: undefined!,

  total: undefined!,
  pageSize: 10,
  page: 1,

  sort: undefined!,
};

const auditLogSlice = createSlice({
  name: "auditLog",
  initialState,
  reducers: {
    loadAuditLog(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadAuditLogSuccess(state, action: PayloadAction<LoadedAuditLogPayloadType>) {
      const { data, count } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.auditLog = data;
      state.total = count;
    },
    loadAuditLogError(state) {
      state.loading = false;
      state.loadingData = false;
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;
      state.loadingData = true;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.loadingData = true;
    },
    sortAuditLog(state, action: PayloadAction<SortObject>) {
      state.sort = {
        key: action.payload.key,
        order: action.payload.order,
      };
      state.loadingData = true;
    },
    search(state) {
      state.loadingData = true;
    },
  },
});

export default auditLogSlice;
