import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import DealModel from "../../../viewModels/Deal/DealModel";

const initialState: {
  loading: boolean;
  loadingData: boolean;

  deal: DealModel;
} = {
  loading: true,
  loadingData: true,

  deal: undefined!,
};

const salesDealSlice = createSlice({
  name: "salesDeal",
  initialState,
  reducers: {
    loadDeal(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<number>,
    ) {
      state.loadingData = true;
    },
    loadDealSuccess(state, action: PayloadAction<DealModel>) {
      state.loading = false;
      state.loadingData = false;
      state.deal = action.payload;
    },
    changeDeal(state, action: PayloadAction<DealModel>) {
      state.deal = action.payload;
    },

    resetState: () => initialState,

    updateDeal(state) {
      state.loadingData = true;
    },
    // updateDealSuccess(state, action: PayloadAction<DealModel>) {
    //   state.loadingData = false;
    //   state.deal = action.payload;
    // },
    updateDealSuccess(state) {
      state.loadingData = false;
      // state.deal = action.payload;
    },

    createDocument(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<number>,
    ) {
      state.loadingData = true;
    },
    createDocumentSuccess(state) {
      state.loadingData = false;
    },
  },
});

export default salesDealSlice;
