import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AuthModalViews, IAuthModalState } from "./types";

import { AuthActions } from "../../../auth/actions";
import AppSlice from "../../App/reducer";

const initialState: IAuthModalState = {
  currentView: "LOGIN_VIEW",

  loginFormErrors: undefined!,
  isLoginLoading: false,

  restorePasswordFormErrors: undefined!,
  isRestorePasswordLoading: false,
  emailForResendRestorePassword: undefined!,

  registrationFormErrors: undefined!,
  isRegisterLoading: false,
  emailForResendRegistration: undefined!,
};

const authModalSlice = createSlice({
  name: "authModal",
  initialState,
  reducers: {
    changeCurrentView(state, action: PayloadAction<AuthModalViews>) {
      state.currentView = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AuthActions.login, (state) => {
      state.isLoginLoading = true;
    });
    builder.addCase(AuthActions.loginSuccess, (state) => {
      state.isLoginLoading = false;
    });
    builder.addCase(AuthActions.loginError, (state, action) => {
      state.loginFormErrors = action.payload;
      state.isLoginLoading = false;
    });
    builder.addCase(AuthActions.register, (state) => {
      state.isRegisterLoading = true;
    });
    builder.addCase(AuthActions.registerSuccess, (state, action) => {
      state.isRegisterLoading = false;
      state.emailForResendRegistration = action.payload;
      state.currentView = "SUCCESS_REGISTER_VIEW";
    });
    builder.addCase(AuthActions.registerError, (state, action) => {
      state.registrationFormErrors = action.payload;
      state.isRegisterLoading = false;
    });
    builder.addCase(AuthActions.restorePassword, (state) => {
      state.isRestorePasswordLoading = true;
    });
    builder.addCase(AuthActions.restorePasswordSuccess, (state, action) => {
      state.isRestorePasswordLoading = false;
      state.emailForResendRestorePassword = action.payload;
      state.currentView = "SUCCESS_RESTORE_PASSWORD_VIEW";
    });
    builder.addCase(AuthActions.restorePasswordError, (state, action) => {
      state.restorePasswordFormErrors = action.payload;
      state.isRestorePasswordLoading = false;
    });
    builder.addCase(AppSlice.actions.closeAuthModal.type, () => initialState);
  },
});

export const { changeCurrentView } = authModalSlice.actions;
export default authModalSlice;
