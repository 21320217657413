import { take, put, call } from "redux-saga/effects";

import { AuthActions, LOGOUT } from "../../actions";

import { authHttpService } from "../../apiService";

export function* logoutFlow() {
  while (true) {
    yield take(LOGOUT);
    yield call([authHttpService, authHttpService.Logout]);
    yield put(AuthActions.logoutSuccess());
  }
}
