import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import LanguageDetector, { DetectorOptions } from "i18next-browser-languagedetector";
import { SERVER_ADDRESSES } from "./config/serverAddressesConfig";
import { DICTIONARY_API_TRANSLATIONS_CONTROLLER_END_POINTS } from "./config/apiEndPoints/DictionaryApiEndsPoints/TranslationsController";

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .use(LanguageDetector)
  .init<HttpBackendOptions & DetectorOptions>({
    backend: {
      loadPath: `${SERVER_ADDRESSES.DICTIONARY_API}/${DICTIONARY_API_TRANSLATIONS_CONTROLLER_END_POINTS.CONTROLLER_PATH}/{{lng}}`,
    },
    ns: ["translations"],
    debug: process.env.NODE_ENV === "development",
    supportedLngs: ["ru", "en", "zh"],
    fallbackLng: "ru",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["querystring", "localStorage", "navigator"],
      lookupQuerystring: "lang",
    },
  });

export default i18n;
