import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ILogistDictionariesCurrenciesPageState, LoadedLogistDictionariesCurrenciesPayloadType, SortObject } from "./types";

const initialState: ILogistDictionariesCurrenciesPageState = {
  loading: true,
  loadingData: true,

  currencies: undefined!,

  total: undefined!,
  pageSize: 10,
  page: 1,

  sort: {
    key: "date",
    order: "descend",
  },
};

const dictionariesCurrenciesSlice = createSlice({
  name: "dictionariesCurrencies",
  initialState,
  reducers: {
    loadCurrencies(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadCurrenciesSuccess(state, action: PayloadAction<LoadedLogistDictionariesCurrenciesPayloadType>) {
      const { currencies, total } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.currencies = currencies;
      state.total = total;
    },
    loadCurrenciesError(state) {
      state.loading = false;
      state.loadingData = false;
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;
      state.loadingData = true;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.loadingData = true;
    },
    sortCurrencies(state, action: PayloadAction<SortObject>) {
      state.sort = {
        key: action.payload.key,
        order: action.payload.order,
      };
      state.loadingData = true;
    },
    search(state) {
      state.loadingData = true;
    },
  },
});

export default dictionariesCurrenciesSlice;
