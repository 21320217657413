import React, { useEffect } from "react";

import { Form, Button, Row } from "antd";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { makeSelectLoginFormErrors } from "../../../../selectors";

import Input from "../../../../../../components/MyInput";
import { Text } from "../../../../../../components/Typegraphy/Text";
import { PasswordWithFormLabel } from "../../../../../../components/Form/FormItemsWithLabel";

import { LoginFormProps, LoginFormInputModel } from "./types";

function LoginForm({ loadingData, onLogin, onOpenRestorePasswordView, onOpenRegisterView }: LoginFormProps) {
  const { t } = useTranslation("translations");
  const [form] = Form.useForm();

  const formErrors = useSelector(makeSelectLoginFormErrors());

  useEffect(() => {
    if (formErrors) {
      const errors = [];

      if (formErrors.find((x) => x.field === "login" && x.errorCode === "2")) {
        errors.push({
          name: "login",
          errors: [t("user_with_such_identification_number_not_found")],
        });
      }

      if (formErrors.find((x) => x.field === "password" && x.errorCode === "4")) {
        errors.push({
          name: "password",
          errors: [t("wrong_password")],
        });
      }

      form.setFields(errors);
    }
  }, [formErrors, form, t]);

  const onFinish = ({ login, password }: LoginFormInputModel) => {
    onLogin({ login, password });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="login"
        rules={[
          () => ({
            validator(_, value: string) {
              if (value && value.length >= 10 && value.length <= 12) {
                return Promise.resolve();
              }
              if (!value) {
                return Promise.reject(t("enter_identification_number"));
              }

              return Promise.reject(t("identification_number_length"));
            },
          }),
        ]}
      >
        <Input placeholder={t("10_12_digits")!} label={t("identification_number")!} mandatory type="only-numbers" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: t("enter_password")! }]}>
        <PasswordWithFormLabel placeholder={t("password")!} label={t("password")!} mandatory />
      </Form.Item>
      <Form.Item>
        <Button type="text" onClick={onOpenRestorePasswordView}>
          {t("forgot_password")}
        </Button>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loadingData}>
          {t("enter")}
        </Button>
      </Form.Item>

      <Row justify="center">
        <Text size={3} margin="0 4px 0">
          {t("is_new_user")}
        </Text>
        <Button onClick={onOpenRegisterView} type="text">
          {t("register")}
        </Button>
      </Row>
    </Form>
  );
}

export default React.memo(LoginForm);
