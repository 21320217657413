import React, { useEffect } from "react";

import { message } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { makeSelectMessageConfigShow } from "../../selectors";

function MessageProvider() {
  const messageConfig = useSelector(makeSelectMessageConfigShow());

  const [messageApi, contextHolder] = message.useMessage();

  const { t } = useTranslation("translations");

  useEffect(() => {
    if (messageConfig) {
      messageApi.open({
        type: messageConfig.type,
        content: messageConfig.orderInfo ? t(messageConfig.key as any, messageConfig.orderInfo) : t(messageConfig.key as any),
        className: `ant-message-notice-${messageConfig.type}`,
        icon: messageConfig.type === "error" ? <CloseCircleOutlined /> : <CheckCircleOutlined />,
        duration: 6,
      });
    }
  }, [messageConfig, messageApi, t]);

  return contextHolder;
}

export default React.memo(MessageProvider);
