import React, { useCallback, useState } from "react";

import { Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import AppSlice from "../App/reducer";

import ContactForm from "./components/ContactForm";

import { ContactFormInputModel } from "./components/ContactForm/types";
import { makeSelectIsContactModalLoading, makeSelectIsContactModalVisible } from "../App/selectors";

function ContactModal() {
  const dispatch = useDispatch();
  const isContactModalVisible = useSelector(makeSelectIsContactModalVisible());
  const isContactModalLoading = useSelector(makeSelectIsContactModalLoading());

  const [isCaptchaError, setIsCaptchaError] = useState<boolean>(false);

  const handleCloseContactModal = useCallback(() => {
    dispatch(AppSlice.actions.closeContactModal());
    setIsCaptchaError(false);
  }, [dispatch]);

  const handleSendFeedback = useCallback(
    (applicationModel: ContactFormInputModel) => {
      dispatch(AppSlice.actions.sendFeedbackForm(applicationModel));
      // if (!captchaToken) {
      //   setIsCaptchaError(true);
      // } else {
      //   dispatch(AppSlice.actions.sendFeedbackForm(applicationModel));
      //   setIsCaptchaError(false);
      // }
    },
    [dispatch],
  );

  const handleCapthaChange = useCallback(() => {
    setIsCaptchaError(false);
  }, []);

  return (
    <Modal
      open={isContactModalVisible}
      onCancel={handleCloseContactModal}
      footer={null}
      destroyOnClose
      closeIcon={<CloseCircleOutlined />}
      width={545}
    >
      <ContactForm
        onSendApplication={handleSendFeedback}
        isDataLoading={isContactModalLoading}
        isCaptchaError={isCaptchaError}
        onCaptchaChange={handleCapthaChange}
      />
    </Modal>
  );
}

export default React.memo(ContactModal);
