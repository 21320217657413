import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  UpdateDictionariesCountryModel,
  CreateDictionariesCountryModel,
} from "../../../../viewModels/Dictionaries/DictionariesCountryModel";

import { ILogistDictionariesCountriesPageState, LoadedLogistDictionariesCountriesPayloadType, SortObject } from "./types";
import { CarrierBasicInfoModel } from "../../../../viewModels/Carriers/CarrierBasicInfoModel";

const initialState: ILogistDictionariesCountriesPageState = {
  loading: true,
  loadingData: true,

  countries: undefined!,

  carriers: undefined!,

  total: undefined!,
  pageSize: 10,
  page: 1,

  sort: undefined!,
  searchFilterValue: "",

  countryIdToEdit: undefined!,
  isEditCountryDrawerLoading: false,
  isEditCountryDrawerOpen: false,
};

const dictionariesCountriesSlice = createSlice({
  name: "dictionariesCountries",
  initialState,
  reducers: {
    loadCountries(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadCountriesSuccess(state, action: PayloadAction<LoadedLogistDictionariesCountriesPayloadType>) {
      const { countries, total } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.countries = countries;
      state.total = total;
    },
    loadCountriesError(state) {
      state.loading = false;
      state.loadingData = false;
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;
      state.loadingData = true;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.loadingData = true;
    },
    sortCountries(state, action: PayloadAction<SortObject>) {
      state.sort = {
        key: action.payload.key,
        order: action.payload.order,
      };
      state.loadingData = true;
    },
    changeSearchFilterValue(state, action: PayloadAction<string>) {
      state.searchFilterValue = action.payload;
    },
    search(state) {
      state.loadingData = true;
      state.page = 1;
    },
    openEditCountryDrawer(state, action: PayloadAction<number | undefined>) {
      state.countryIdToEdit = action.payload!;
      state.isEditCountryDrawerOpen = true;
    },
    closeEditCountryDrawer(state) {
      if (!state.isEditCountryDrawerLoading) {
        state.countryIdToEdit = undefined!;
        state.isEditCountryDrawerOpen = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    createCountry(state, action: PayloadAction<CreateDictionariesCountryModel>) {
      state.isEditCountryDrawerLoading = true;
    },
    createCountrySuccess(state) {
      state.isEditCountryDrawerLoading = false;
      state.isEditCountryDrawerOpen = false;
    },
    createCountryError(state) {
      state.isEditCountryDrawerLoading = false;
      state.isEditCountryDrawerOpen = false;
    },
    // eslint-disable-next-line no-unused-vars
    updateCountry(state, action: PayloadAction<UpdateDictionariesCountryModel>) {
      state.isEditCountryDrawerLoading = true;
    },
    updateCountrySuccess(state, action: PayloadAction<UpdateDictionariesCountryModel>) {
      state.isEditCountryDrawerLoading = false;
      state.isEditCountryDrawerOpen = false;
      const index = state.countries.findIndex((x) => x.id === action.payload.id);
      state.countries[index] = action.payload;
    },
    updateCountryError(state) {
      state.isEditCountryDrawerLoading = false;
      state.isEditCountryDrawerOpen = false;
    },

    loadCarriers() {},
    loadCarriersSuccess(state, action: PayloadAction<Array<CarrierBasicInfoModel>>) {
      state.carriers = action.payload;
    },
  },
});

export default dictionariesCountriesSlice;
