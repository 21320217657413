/* eslint-disable react/function-component-definition */
import React, { ComponentType } from "react";
import ControlLabel from "../ControlLabel";

const withFormLabel =
  <TProps extends unknown>(Component: ComponentType<TProps>) =>
  ({ label, additionalLabel, mandatory, ...props }: TProps & { label?: string; mandatory?: boolean; additionalLabel?: string }) =>
    (
      <>
        {label && (
          <div style={{ display: "flex" }}>
            <ControlLabel>
              {label}
              {mandatory && "*"}
            </ControlLabel>
            {additionalLabel}
          </div>
        )}
        <Component {...(props as any)} />
      </>
    );
export default withFormLabel;
