import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ChangePasswordInputModel } from "../../../../../../viewModels/Auth/ChangePasswordInputModel";

import { FormErrorModel } from "../../../../../../viewModels/General/ErrorModel";

import { ISecurityPageState } from "./types";

const initialState: ISecurityPageState = {
  loading: false,
  loadingData: false,
  changePasswordFormErrors: undefined!,
};

const personalAreaSlice = createSlice({
  name: "security",
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    changePassword(state, action: PayloadAction<ChangePasswordInputModel>) {
      state.loadingData = true;
    },
    changePasswordSuccess(state) {
      state.loadingData = false;
    },
    changePasswordError(state, action: PayloadAction<Array<FormErrorModel>>) {
      state.changePasswordFormErrors = action.payload;
      state.loadingData = false;
    },
  },
});

export default personalAreaSlice;
