import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CreateDictionariesRouteModel, UpdateDictionariesRouteModel } from "../../../../viewModels/Dictionaries/DictionariesRouteModel";

import { ILogistDictionariesRoutesPageState, LoadedLogistDictionariesRoutesPayloadType, SortObject } from "./types";
import LocationModel from "../../../../viewModels/Location/LocationModel";
import { CarrierBasicInfoModel } from "../../../../viewModels/Carriers/CarrierBasicInfoModel";
import { ROUTES_DICTIONARY_PAGE_TAB_KEYS } from "../constants";

const initialState: ILogistDictionariesRoutesPageState = {
  loading: true,
  loadingData: true,
  activeApplicationsTabId: ROUTES_DICTIONARY_PAGE_TAB_KEYS.ALL,

  routes: undefined!,

  locations: undefined!,

  carriers: undefined!,

  isFiltersDrawerVisible: false,
  isFiltersDrawerLoading: false,

  total: undefined!,
  pageSize: 10,
  page: 1,

  sort: undefined!,
  searchFilterValue: "",

  routeIdToEdit: undefined!,
  isEditRouteDrawerLoading: false,
  isEditRouteDrawerOpen: false,

  filters: {
    fromLocationId: undefined!,
    toLocationId: undefined!,
    date: {
      from: undefined!,
      to: undefined!,
    },
    isExpired: undefined!,
    isDanger: undefined!,
    isOnlyHighWayRoutes: undefined!,
    transportCharacteristicId: undefined!,
    transportationTypeId: undefined!,
    carrierId: undefined!,
  },

  table: {
    isAllSelect: false,
    isNotAllSelect: false,
    selectedRoutesIds: [],
    exceptionRoutesIds: [],
  },
};

const dictionariesRoutesSlice = createSlice({
  name: "dictionariesRoutes",
  initialState,
  reducers: {
    exportRoutes() {},
    resetTableState(state) {
      state.table = initialState.table;
    },
    setIsAllSelect(state, action: PayloadAction<boolean>) {
      state.table.isAllSelect = action.payload;
    },
    setIsNotAllSelect(state, action: PayloadAction<boolean>) {
      state.table.isNotAllSelect = action.payload;
    },
    setSelectedRoutesIds(state, action: PayloadAction<number[]>) {
      state.table.selectedRoutesIds = action.payload;
    },
    setExceptionRoutesIds(state, action: PayloadAction<number[]>) {
      state.table.exceptionRoutesIds = action.payload;
    },
    setLoadDataStatus(state, action: PayloadAction<boolean>) {
      state.loadingData = action.payload;
    },
    changeActiveApplicationsTabId(state, action: PayloadAction<ROUTES_DICTIONARY_PAGE_TAB_KEYS>) {
      state.loadingData = true;
      state.page = 1;
      state.activeApplicationsTabId = action.payload;
    },
    loadRoutes(state) {
      state.loadingData = true;
      state.page = 1;
    },
    setLoadedRoutes(state, action: PayloadAction<LoadedLogistDictionariesRoutesPayloadType>) {
      const { routes, total } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.routes = routes;
      state.total = total;
      state.isFiltersDrawerVisible = false;
      state.isFiltersDrawerLoading = false;
    },
    loadRoutesReject(state) {
      state.loading = false;
      state.loadingData = false;
    },
    loadLocationsSuccess(state, action: PayloadAction<Array<LocationModel>>) {
      state.locations = action.payload;
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;
      state.loadingData = true;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.loadingData = true;
    },
    sortRoutes(state, action: PayloadAction<SortObject>) {
      state.sort = {
        key: action.payload.key,
        order: action.payload.order,
      };
      state.loadingData = true;
    },
    openFiltersDrawer(state) {
      state.isFiltersDrawerVisible = true;
    },
    closeFiltersDrawer(state) {
      if (!state.isFiltersDrawerLoading) {
        state.isFiltersDrawerVisible = false;
      }
    },
    filterRoutes(state, action: PayloadAction<any>) {
      state.filters = action.payload;
      state.loadingData = true;
      state.isFiltersDrawerLoading = true;
      state.page = 1;
    },
    clearFilter(state, action: PayloadAction<keyof any>) {
      state.loadingData = true;
      state.filters = { ...state.filters, [action.payload]: undefined! };
    },
    openEditRouteDrawer(state, action: PayloadAction<number | undefined>) {
      state.routeIdToEdit = action.payload!;
      state.isEditRouteDrawerOpen = true;
    },
    closeEditRouteDrawer(state) {
      if (!state.isEditRouteDrawerLoading) {
        state.routeIdToEdit = undefined!;
        state.isEditRouteDrawerOpen = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    createRoute(state, action: PayloadAction<CreateDictionariesRouteModel>) {
      state.isEditRouteDrawerLoading = true;
    },
    createRouteSuccess(state) {
      state.isEditRouteDrawerLoading = false;
      state.isEditRouteDrawerOpen = false;
    },
    createRouteReject(state) {
      state.isFiltersDrawerLoading = false;
      state.isEditRouteDrawerOpen = false;
    },
    // eslint-disable-next-line no-unused-vars
    updateRoute(state, action: PayloadAction<UpdateDictionariesRouteModel>) {
      state.isEditRouteDrawerLoading = true;
    },
    updateRouteSuccess(state) {
      state.isEditRouteDrawerLoading = false;
      state.isEditRouteDrawerOpen = false;
    },
    updateRouteReject(state) {
      state.isEditRouteDrawerLoading = false;
      state.isEditRouteDrawerOpen = false;
    },

    loadCarriers() {},
    loadCarriersSuccess(state, action: PayloadAction<Array<CarrierBasicInfoModel>>) {
      state.carriers = action.payload;
    },

    markForDeletion(state) {
      state.loadingData = true;
    },

    unmarkForDeletion(state) {
      state.loadingData = true;
    },
  },
});

export default dictionariesRoutesSlice;
