import React from "react";
import ReactDOM from "react-dom/client";

import "react-datepicker/dist/react-datepicker.css";

import duration from "dayjs/plugin/duration";

import { createBrowserHistory } from "history";

import { Provider } from "react-redux";

import dayjs from "dayjs";

import CustomRouter from "./components/Router";

import { store } from "./configureStore";

import "antd/dist/antd.min.css";

import "./i18n";

import GlobalStyles from "./styled";
import LoadingIndicator from "./components/LoadingIndicator";
import LocaleProvider from "./LocaleProvider";

dayjs.extend(duration);

export const history = createBrowserHistory();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <CustomRouter history={history}>
      <GlobalStyles host={store.getState().global.host as "WEB" | "BITRIX"} />
      <React.Suspense fallback={<LoadingIndicator />}>
        <LocaleProvider />
      </React.Suspense>
    </CustomRouter>
  </Provider>,
);
