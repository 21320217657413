import React from "react";
import loadable from "@loadable/component";

import LoadingIndicator from "../../../components/LoadingIndicator";

const LodableComponent: any = loadable(() => import("./index"), {
  fallback: <LoadingIndicator />,
});

export default LodableComponent;
