import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CreateDictionariesApplicationServiceModel,
  UpdateDictionariesApplicationServiceModel,
} from "../../../../viewModels/Dictionaries/DictionariesApplicationServiceModel";

import { IDictionariesApplicationServicesPageState, LoadedDictionariesApplicationServicesPayloadType, SortObject } from "./types";

import IDictionariesApplicationServiceDto from "../../../../dto/Dictionaries/IDictionariesApplicationServiceDto";

const initialState: IDictionariesApplicationServicesPageState = {
  loading: true,
  loadingData: true,

  applicationServices: undefined!,

  total: undefined!,
  pageSize: 10,
  page: 1,

  sort: undefined!,

  applicationServiceIdToEdit: undefined!,
  isEditApplicationServiceDrawerLoading: false,
  isEditApplicationServiceDrawerOpen: false,
};

const dictionariesApplicationServicesSlice = createSlice({
  name: "dictionariesApplicationServices",
  initialState,
  reducers: {
    loadApplicationServices(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadApplicationServicesSuccess(state, action: PayloadAction<LoadedDictionariesApplicationServicesPayloadType>) {
      const { applicationServices, total } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.applicationServices = applicationServices;
      state.total = total;
    },
    loadApplicationServicesError(state) {
      state.loading = false;
      state.loadingData = false;
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;
      state.loadingData = true;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.loadingData = true;
    },
    sortApplicationServices(state, action: PayloadAction<SortObject>) {
      state.sort = {
        key: action.payload.key,
        order: action.payload.order,
      };
      state.loadingData = true;
    },
    search(state) {
      state.loadingData = true;
    },
    openEditApplicationServiceDrawer(state, action: PayloadAction<number | undefined>) {
      state.applicationServiceIdToEdit = action.payload!;
      state.isEditApplicationServiceDrawerOpen = true;
    },
    closeEditApplicationServiceDrawer(state) {
      if (!state.isEditApplicationServiceDrawerLoading) {
        state.applicationServiceIdToEdit = undefined!;
        state.isEditApplicationServiceDrawerOpen = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    createApplicationService(state, action: PayloadAction<CreateDictionariesApplicationServiceModel>) {
      state.isEditApplicationServiceDrawerLoading = true;
    },
    createApplicationServiceSuccess(state) {
      state.isEditApplicationServiceDrawerLoading = false;
      state.isEditApplicationServiceDrawerOpen = false;
    },
    createApplicationServiceError(state) {
      state.isEditApplicationServiceDrawerLoading = false;
    },
    // eslint-disable-next-line no-unused-vars
    updateApplicationService(state, action: PayloadAction<UpdateDictionariesApplicationServiceModel>) {
      state.isEditApplicationServiceDrawerLoading = true;
    },
    updateApplicationServiceSuccess(state, action: PayloadAction<IDictionariesApplicationServiceDto>) {
      const index = state.applicationServices.findIndex((x) => x.id === action.payload.id);
      state.applicationServices[index] = action.payload;
      state.isEditApplicationServiceDrawerLoading = false;
      state.isEditApplicationServiceDrawerOpen = false;
    },
    updateApplicationServiceError(state) {
      state.isEditApplicationServiceDrawerLoading = false;
    },
    // eslint-disable-next-line no-unused-vars
    deleteApplicationService(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },
    deleteApplicationServiceSuccess(state) {
      state.loadingData = false;
    },
    deleteApplicationServiceError(state) {
      state.loadingData = false;
    },
  },
});

export default dictionariesApplicationServicesSlice;
