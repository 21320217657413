import styled from "styled-components";

interface AutoCompleteContainerProps {
  hasPrefixIcon: boolean;
}

export const Option = styled.div`
  display: flex;

  img {
    margin-right: 8px;
  }
`;

export const AutoCompleteContainer = styled.div<AutoCompleteContainerProps>`
  position: relative;

  .ant-select-selection-search-input {
    ${({ hasPrefixIcon }) => hasPrefixIcon && "padding-left: 30px !important;"}
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;
