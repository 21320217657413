import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { INotificationsState } from "./types";
import { NotificationModel } from "../../../../viewModels/Notification/NotificationModel";

const initialState: INotificationsState = {
  loadingData: true,

  notifications: undefined!,

  hasUnreadNotifications: false,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    loadNotifications(state) {
      state.loadingData = true;
    },
    loadNotificationsSuccess(state, action: PayloadAction<Array<NotificationModel>>) {
      state.loadingData = false;
      state.notifications = action.payload;
    },
    loadNotificationsError(state) {
      state.loadingData = false;
    },

    startGettingUnreadNotifications() {},
    stopGettingUnreadNotifications() {},

    getUnreadNotificationsSuccess(state, action: PayloadAction<boolean>) {
      state.hasUnreadNotifications = action.payload;
    },

    // eslint-disable-next-line
    readNotification(state, action: PayloadAction<number>) {},
    readNotificationSuccess(state, action: PayloadAction<number>) {
      state.notifications = state.notifications.map((notification) =>
        notification.id === action.payload ? { ...notification, read: true } : { ...notification },
      );
      state.hasUnreadNotifications = false;
    },

    readAllNotifications() {},
    readAllNotificationsSuccess(state) {
      state.notifications = state.notifications.map((notification) => ({ ...notification, read: true }));
    },

    // eslint-disable-next-line
    deleteNotification(state, action: PayloadAction<number>) {},
    deleteNotificationSuccess(state, action: PayloadAction<number>) {
      state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
    },

    deleteAllNotifications() {},
    deleteAllNotificationsSuccess(state) {
      state.notifications = undefined!;
      state.hasUnreadNotifications = false;
    },
  },
});

export default notificationsSlice;
