import { take, fork, put, call } from "redux-saga/effects";

import { ApiCallResult } from "../../../api/types";

import { AuthActions, LOGIN, LOGIST_LOGIN } from "../../actions";

import { authHttpService } from "../../apiService";

import { generateUserInfo } from "../../utils";

import { history } from "../../..";

function* authorizeByCredentialsFlow() {
  while (true) {
    const { payload }: ReturnType<typeof AuthActions.login> = yield take(LOGIN);
    const { login, password } = payload;
    const { success, reject }: ApiCallResult = yield call([authHttpService, authHttpService.LoginByCredentials], login, password);
    if (success) {
      yield put(AuthActions.loginSuccess(success.payload));
    }

    if (reject) {
      yield put(AuthActions.loginError(reject.payload.errors!));
    }
  }
}

function* logistAuthorizeByCredentialsFlow() {
  while (true) {
    const { payload }: ReturnType<typeof AuthActions.logistLogin> = yield take(LOGIST_LOGIN);
    const { login, password } = payload;
    const { success, reject }: ApiCallResult = yield call([authHttpService, authHttpService.LoginByCredentials], login, password);

    if (success) {
      const userInfo = generateUserInfo(success.payload);
      yield put(AuthActions.logistLoginSuccess(userInfo));
      history.push("/");
    }

    if (reject) {
      yield put(AuthActions.logistLoginError(reject.payload.errors!));
    }
  }
}

export function* loginFlow() {
  yield fork(authorizeByCredentialsFlow);
  yield fork(logistAuthorizeByCredentialsFlow);
}
