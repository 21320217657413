import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IDictionariesCompaniesPage, LoadedCompaniesPayloadType, SortObject } from "./types";

const initialState: IDictionariesCompaniesPage = {
  loading: true,
  loadingData: true,
  companies: undefined!,
  total: undefined!,
  pageSize: 10,
  page: 1,

  sort: undefined!,

  searchFilterValue: "",
};

const dictionariesCompaniesSlice = createSlice({
  name: "dictionariesCompanies",
  initialState,
  reducers: {
    loadCompanies(state) {
      state.loadingData = true;
    },
    loadCompaniesSuccess(state, action: PayloadAction<LoadedCompaniesPayloadType>) {
      const { companies, total, pageSize, page } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.companies = companies;
      state.total = total;
      state.pageSize = pageSize;
      state.page = page;
    },
    loadCompaniesError(state) {
      state.loadingData = false;
    },

    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;
      state.loadingData = true;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.loadingData = true;
    },
    sortCompanies(state, action: PayloadAction<SortObject>) {
      state.sort = {
        key: action.payload.key,
        order: action.payload.order,
      };
      state.loadingData = true;
    },

    changeSearchFilterValue(state, action: PayloadAction<string>) {
      state.searchFilterValue = action.payload;
    },
    search(state) {
      state.loadingData = true;
      state.page = 1;
    },
  },
});

export default dictionariesCompaniesSlice;
