import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICompanyDetailsState, LoadedDocumentsPayloadType, LoadFilePayloadType } from "./types";
import { DictionariesCompanyModel } from "../../../../viewModels/Dictionaries/DictionariesCompanyModel";
import { ChangePasswordInputModel } from "../../../../viewModels/Auth/ChangePasswordInputModel";
import { FormErrorModel } from "../../../../viewModels/General/ErrorModel";
import { CompaniesTabs } from "..";

const initialState: ICompanyDetailsState = {
  loading: true,
  loadingData: true,

  activeCompanyTabId: undefined!,

  changePasswordFormErrors: undefined!,

  companyDetails: undefined!,

  pageSize: 10,
  page: 1,
  total: undefined!,

  isFileModalOpen: false,
  isFileLoading: false,
  file: undefined!,
  filename: "",
  typeId: 0,

  documents: undefined!,

  documentsPageSize: 10,
  documentsPage: 1,
  documentsTotal: undefined!,
};

const companyDetailsSlice = createSlice({
  name: "companyDetailsPage",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.loadingData = action.payload;
    },

    // eslint-disable-next-line no-unused-vars
    loadCompanyDetails(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },
    loadCompanyDetailsSuccess(state, action: PayloadAction<DictionariesCompanyModel>) {
      state.loading = false;
      state.loadingData = false;
      state.companyDetails = action.payload;
    },
    loadApplicationDetailsError() {},
    resetCompanyDetails(state) {
      state.companyDetails = undefined!;
      state.activeCompanyTabId = undefined!;
    },

    // eslint-disable-next-line
    createCompany(state, action: PayloadAction<any>) {
      state.loadingData = true;
    },
    createCompanySuccess(state) {
      state.loadingData = false;
    },
    createCompanyError(state) {
      state.loadingData = false;
    },

    // eslint-disable-next-line
    updateCompany(state, action: PayloadAction<any>) {
      state.loadingData = true;
    },
    updateCompanySuccess(state) {
      state.loadingData = false;
    },
    updateCompanyError(state) {
      state.loadingData = false;
    },

    changeActiveCompanyTabId(state, action: PayloadAction<CompaniesTabs>) {
      state.activeCompanyTabId = action.payload;
    },

    // eslint-disable-next-line
    changePassword(state, action: PayloadAction<ChangePasswordInputModel>) {
      state.loadingData = true;
    },
    changePasswordSuccess(state) {
      state.loadingData = false;
    },
    changePasswordError(state, action: PayloadAction<Array<FormErrorModel>>) {
      state.changePasswordFormErrors = action.payload;
      state.loadingData = false;
    },

    // eslint-disable-next-line
    loadDocuments(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },
    loadDocumentsSuccess(state, action: PayloadAction<LoadedDocumentsPayloadType>) {
      const { documents, page, pageSize, total } = action.payload;
      state.loadingData = false;
      state.documents = documents;
      state.documentsPage = page;
      state.documentsPageSize = pageSize;
      state.documentsTotal = total;
      state.isFileLoading = false;
      state.isFileModalOpen = false;
      state.file = undefined!;
      state.filename = "";
      state.typeId = 0;
    },
    loadDocumentsError(state) {
      state.loadingData = false;
    },
    openFileModal(state) {
      state.isFileModalOpen = true;
    },
    closeFileModal(state) {
      state.isFileModalOpen = false;
    },
    uploadDocument(state, action: PayloadAction<LoadFilePayloadType>) {
      const { file, filename, typeId } = action.payload;
      state.isFileLoading = true;
      state.file = file;
      state.filename = filename;
      state.typeId = typeId;
    },
    uploadDocumnetError(state) {
      state.isFileLoading = false;
    },

    // eslint-disable-next-line
    downloadDocument(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },
    downloadDocumentSuccess(state) {
      state.loadingData = false;
    },
    downloadDocumentError(state) {
      state.loadingData = false;
    },

    changeDocumentsPageSize(state, action: PayloadAction<any>) {
      state.documentsPageSize = action.payload.pageSize;
      state.documentsPage = 1;
      state.loadingData = true;
    },
    changeDocumentsPage(state, action: PayloadAction<any>) {
      state.documentsPage = action.payload.page;
      state.loadingData = true;
    },

    // eslint-disable-next-line
    deleteDocument(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },
    deleteDocumentError(state) {
      state.loadingData = false;
    },
  },
});

export default companyDetailsSlice;
