import { fork } from "redux-saga/effects";

import { loginFlow } from "./watcher/loginFlow";
import { registerFlow } from "./watcher/registerFlow";
import { restorePasswordFlow } from "./watcher/restorePassword";
import { logoutFlow } from "./watcher/logoutFlow";

export default function* authSaga() {
  yield fork(loginFlow);
  yield fork(registerFlow);
  yield fork(restorePasswordFlow);
  yield fork(logoutFlow);
}
