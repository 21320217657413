import { IApiRequest } from "../types";

import httpService from "./http-service";

export default function chooseRequest({ requestType }: IApiRequest): (request: IApiRequest, host: "WEB" | "BITRIX") => Promise<unknown> {
  switch (requestType) {
    case "HTTP":
      return httpService;
    default:
      return httpService;
  }
}
