import React, { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { makeSelectIsLoginLoading } from "../../selectors";

import { AuthActions } from "../../../../auth/actions";

import { changeCurrentView } from "../../reducer";

import { H3 } from "../../../../components/Typegraphy/Heading";
import { Text } from "../../../../components/Typegraphy/Text";

import LoginForm from "./components/LoginForm";

import { LoginInputModel } from "../../../../viewModels/Auth/LoginInputModel";

function LoginView() {
  const isLoginLoading = useSelector(makeSelectIsLoginLoading());
  const dispatch = useDispatch();
  const { t } = useTranslation("translations");

  const handleLogin = useCallback(
    (loginModel: LoginInputModel) => {
      dispatch(AuthActions.login(loginModel));
    },
    [dispatch],
  );

  const handleOpenRegisterView = useCallback(() => {
    dispatch(changeCurrentView("REGISTER_VIEW"));
  }, [dispatch]);

  const handleOpenRestorePasswordView = useCallback(() => {
    dispatch(changeCurrentView("RESTORE_PASSWORD_VIEW"));
  }, [dispatch]);

  return (
    <>
      <H3 margin="0 0 16px 0">{t("login_to_account")}</H3>
      <Text margin="0 0 16px 0">{t("login_to_get_an_accurate_calculation")}</Text>
      <LoginForm
        onLogin={handleLogin}
        loadingData={isLoginLoading}
        onOpenRestorePasswordView={handleOpenRestorePasswordView}
        onOpenRegisterView={handleOpenRegisterView}
      />
    </>
  );
}

export default React.memo(LoginView);
