import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ApplicationStatisticModel from "../../../viewModels/Applications/ApplicationStatisticModel";

const initialState: {
  loading: boolean;
  loadingData: boolean;
  statistics?: Array<ApplicationStatisticModel>;
} = {
  loading: true,
  loadingData: true,
};

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    loadStatistics(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },
    loadStatisticsSuccess(state, action: PayloadAction<Array<ApplicationStatisticModel>>) {
      state.loadingData = false;
      state.statistics = action.payload;
    },
  },
});

export default statisticsSlice;
