import React, { useCallback, useEffect } from "react";

// eslint-disable-next-line
import { useNavigate } from "react-router";

import { Form, Button, Row } from "antd";

import InputMask from "react-input-mask";

import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import AppSlice from "../../../../../App/reducer";

import { makeSelectRegistrationFormErrors } from "../../../../selectors";

import Input from "../../../../../../components/MyInput";
import { Text } from "../../../../../../components/Typegraphy/Text";

import { Agreements } from "./styled";

import { RegisterFormProps, RegisterFormInputModel } from "./types";
import { phoneNumberRegExp } from "../../../../../../utils/regExps";

function RegisterForm({ loadingData, onRegister, onOpenLoginView }: RegisterFormProps) {
  const { t } = useTranslation("translations");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formErrors = useSelector(makeSelectRegistrationFormErrors());

  useEffect(() => {
    if (formErrors) {
      const errors = [];

      if (formErrors.find((x) => x.field === "login" && x.errorCode === "3")) {
        errors.push({
          name: "login",
          errors: [t("identification_number_already_registered")],
        });
      }

      if (formErrors.find((x) => x.field === "email" && x.errorCode === "3")) {
        errors.push({
          name: "password",
          errors: [t("email_already_registered")],
        });
      }
      form.setFields(errors);
    }
  }, [formErrors, form, t]);

  const onFinish = ({ login, companyName, phoneNumber, email }: RegisterFormInputModel) => {
    onRegister({ login, companyName, phoneNumber, email });
  };

  const handleBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length >= 10 && e.target.value.length <= 12) {
        (async () => {
          try {
            const response = await fetch(`https://egrul.itsoft.ru/short_data/?${e.target.value}}`);
            const result: any = await response.json();
            form.setFields([
              {
                name: "companyName",
                value: result.full_name,
                errors: undefined,
              },
            ]);
          } catch (err) {
            console.log("No registered companies found");
          }
        })();
      }
    },
    [form],
  );

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="login"
        rules={[
          () => ({
            validator(_, value: string) {
              if (value && value.length >= 10 && value.length <= 12) {
                return Promise.resolve();
              }
              if (!value) {
                return Promise.reject(t("enter_identification_number"));
              }
              return Promise.reject(t("identification_number_length"));
            },
          }),
        ]}
      >
        <Input placeholder={t("10_12_digits")!} label={t("identification_number")!} mandatory type="only-numbers" onBlur={handleBlur} />
      </Form.Item>

      <Form.Item name="companyName" rules={[{ required: true, message: t("enter_company_name")! }]}>
        <Input placeholder={t("company_name")!} label={t("company_name")!} mandatory />
      </Form.Item>

      <Form.Item
        name="phoneNumber"
        rules={[
          () => ({
            validator(_, value: string) {
              if (phoneNumberRegExp.test(value)) {
                return Promise.resolve();
              }
              if (!value) {
                return Promise.reject(t("enter_phone_number"));
              }
              return Promise.reject(t("enter_phone_number_in_format"));
            },
          }),
        ]}
      >
        <InputMask mask="+7 (999) 999 - 9999">
          <Input placeholder="+7 (___) ___ - ____" label={t("phone_number")!} mandatory />
        </InputMask>
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          { required: true, message: t("enter_email")! },
          { type: "email", message: t("wrong_email")! },
        ]}
      >
        <Input placeholder="Email" label="Email" mandatory />
      </Form.Item>

      <Agreements
        onClick={() => {
          dispatch(AppSlice.actions.closeAuthModal());
          navigate("/privacy/some1");
        }}
      >
        {t("register_agreements_text")} <span>{t("policy_personal_date")}</span>
      </Agreements>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loadingData}>
          {t("register")}
        </Button>
      </Form.Item>

      <Row justify="center">
        <Text size={3} margin="0 4px 0">
          {t("is_already_registered")}
        </Text>
        <Button onClick={onOpenLoginView} type="text">
          {t("enter")}
        </Button>
      </Row>
    </Form>
  );
}

export default React.memo(RegisterForm);
