import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApplicationModel } from "../../../viewModels/Applications/ApplicationModel";
import RouteModel from "../../../viewModels/Routes/RouteModel";

const initialState: {
  loading: boolean;
  loadingData: boolean;
  loadingSuggestions: boolean;

  application: ApplicationModel;
  applicationSuggestions?: Array<{
    application: ApplicationModel;
    matchPercentage: number;
    matchPercentages: { fromLocation: number; incotermsType: number; toLocation: number; transportCharacteristic: number };
  }>;
  selectedRoute?: RouteModel;
} = {
  loading: true,
  loadingData: true,
  loadingSuggestions: false,

  application: undefined!,
};

const logisticialApplicationSlice = createSlice({
  name: "logisticialApplication",
  initialState,
  reducers: {
    loadApplication(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<number>,
    ) {
      state.loadingData = true;
    },
    loadApplicationSuccess(
      state,
      action: PayloadAction<{
        application: ApplicationModel;
        selectedRoute?: RouteModel;
      }>,
    ) {
      const { application, selectedRoute } = action.payload;

      state.loading = false;
      state.loadingData = false;
      state.application = application;
      if (selectedRoute) {
        state.selectedRoute = selectedRoute;
      }
    },

    resetState: () => initialState,

    updateApplication(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<Omit<ApplicationModel, "type" | "creationDate" | "indicativeCreationDate" | "creator">>,
    ) {
      state.loadingData = true;
    },
    updateApplicationSuccess(state, action: PayloadAction<ApplicationModel>) {
      state.loadingData = false;
      state.application = action.payload;
    },
    loadApplicationSuggestions(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<number>,
    ) {
      state.loadingSuggestions = true;
    },
    loadApplicationSuggestionsSuccess(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<
        Array<{
          application: ApplicationModel;
          matchPercentage: number;
          matchPercentages: { fromLocation: number; incotermsType: number; toLocation: number; transportCharacteristic: number };
        }>
      >,
    ) {
      state.loadingSuggestions = false;
      state.applicationSuggestions = action.payload;
    },
  },
});

export default logisticialApplicationSlice;
