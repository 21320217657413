import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IDictionariesRatesUpdatePageState, LoadedDictionariesRatesUpdatePayloadType, SortObject } from "./types";

const initialState: IDictionariesRatesUpdatePageState = {
  loading: true,
  loadingData: true,

  ratesUpdate: undefined!,

  total: undefined!,
  pageSize: 10,
  page: 1,

  sort: undefined!,
};

const dictionariesRatesUpdateSlice = createSlice({
  name: "dictionariesRatesUpdate",
  initialState,
  reducers: {
    loadRatesUpdate(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadRatesUpdateSuccess(state, action: PayloadAction<LoadedDictionariesRatesUpdatePayloadType>) {
      const { ratesUpdate, total } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.ratesUpdate = ratesUpdate;
      state.total = total;
    },
    loadRatesUpdateError(state) {
      state.loading = false;
      state.loadingData = false;
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;
      state.loadingData = true;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.loadingData = true;
    },
    sortRatesUpdate(state, action: PayloadAction<SortObject>) {
      state.sort = {
        key: action.payload.key,
        order: action.payload.order,
      };
      state.loadingData = true;
    },
  },
});

export default dictionariesRatesUpdateSlice;
