import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CreateDictionariesLocationModel,
  UpdateDictionariesLocationModel,
} from "../../../../viewModels/Dictionaries/DictionariesLocationModel";

import { ILogistDictionariesLocationsPageState, LoadedLogistDictionariesLocationsPayloadType, SortObject } from "./types";
import { CarrierBasicInfoModel } from "../../../../viewModels/Carriers/CarrierBasicInfoModel";
import { LOCATIONS_DICTIONARY_PAGE_TAB_KEYS } from "../constants";

const initialState: ILogistDictionariesLocationsPageState = {
  loading: true,
  loadingData: true,
  activeTab: LOCATIONS_DICTIONARY_PAGE_TAB_KEYS.ALL,

  locations: undefined!,

  carriers: undefined!,

  total: undefined!,
  pageSize: 10,
  page: 1,

  sort: undefined!,
  searchFilterValue: "",

  locationIdToEdit: undefined!,
  isEditLocationsDrawerLoading: false,
  isEditLocationDrawerOpen: false,
};

const dictionariesLocationsSlice = createSlice({
  name: "dictionariesLocations",
  initialState,
  reducers: {
    loadLocations(state) {
      state.loadingData = true;
      state.page = 1;
    },
    setLoadedLocations(state, action: PayloadAction<LoadedLogistDictionariesLocationsPayloadType>) {
      const { locations, total } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.locations = locations;
      state.total = total;
    },
    setActiveTab: (state, action: PayloadAction<LOCATIONS_DICTIONARY_PAGE_TAB_KEYS>) => {
      state.loadingData = true;
      state.activeTab = action.payload;
    },
    loadLocationsReject(state) {
      state.loading = false;
      state.loadingData = false;
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;
      state.loadingData = true;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.loadingData = true;
    },
    sortLocations(state, action: PayloadAction<SortObject>) {
      state.sort = {
        key: action.payload.key,
        order: action.payload.order,
      };
      state.loadingData = true;
    },
    changeSearchFilterValue(state, action: PayloadAction<string>) {
      state.searchFilterValue = action.payload;
    },
    search(state) {
      state.loadingData = true;
      state.page = 1;
    },
    openEditLocationDrawer(state, action: PayloadAction<number | undefined>) {
      state.locationIdToEdit = action.payload!;
      state.isEditLocationDrawerOpen = true;
    },
    closeEditLocationDrawer(state) {
      if (!state.isEditLocationsDrawerLoading) {
        state.locationIdToEdit = undefined!;
        state.isEditLocationDrawerOpen = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    createLocation(state, action: PayloadAction<CreateDictionariesLocationModel>) {
      state.isEditLocationsDrawerLoading = true;
    },
    createLocationSuccess(state) {
      state.isEditLocationsDrawerLoading = false;
      state.isEditLocationDrawerOpen = false;
    },
    createLocationReject(state) {
      state.isEditLocationsDrawerLoading = false;
      state.isEditLocationDrawerOpen = false;
    },
    // eslint-disable-next-line no-unused-vars
    updateLocation(state, action: PayloadAction<UpdateDictionariesLocationModel>) {
      state.isEditLocationsDrawerLoading = true;
    },
    updateLocationSuccess(state) {
      state.isEditLocationsDrawerLoading = false;
      state.isEditLocationDrawerOpen = false;
    },
    updateLocationReject(state) {
      state.isEditLocationsDrawerLoading = false;
      state.isEditLocationDrawerOpen = false;
    },

    loadCarriers() {},
    loadCarriersSuccess(state, action: PayloadAction<Array<CarrierBasicInfoModel>>) {
      state.carriers = action.payload;
    },
  },
});

export default dictionariesLocationsSlice;
