import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ApplicationCoefficentModel } from "../../../viewModels/Applications/ApplicationCoefficentModel";

type ApplicationCoefficientsPageState = {
  loading: boolean;
  loadingData: boolean;
  applicationCoefficients: ApplicationCoefficentModel;
};

const initialState: ApplicationCoefficientsPageState = {
  loading: true,
  loadingData: true,

  applicationCoefficients: undefined!,
};

const applicationCoefficientsSlice = createSlice({
  name: "applicationCoefficients",
  initialState,
  reducers: {
    loadApplicationCoefficients(state) {
      state.loadingData = true;
    },
    loadApplicationCoefficientsSuccess(state, action: PayloadAction<ApplicationCoefficentModel>) {
      state.loading = false;
      state.loadingData = false;
      state.applicationCoefficients = action.payload;
    },
    loadApplicationCoefficientsError(state) {
      state.loading = false;
      state.loadingData = false;
    },

    // eslint-disable-next-line no-unused-vars
    updateApplicationCoefficients(state, action: PayloadAction<ApplicationCoefficentModel>) {
      state.loadingData = true;
    },
    updateApplicationCoefficientsSuccess(state) {
      state.loadingData = false;
    },
    updateApplicationCoefficientsError(state) {
      state.loadingData = false;
    },
  },
});

export default applicationCoefficientsSlice;
