import styled from "styled-components";

export const Agreements = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 32px;

  span {
    cursor: pointer;
    color: var(--color-green-700);
    &:hover {
      color: var(--color-green-800);
    }
  }
`;
