import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import CommercialOfferModel from "../../viewModels/CommercialOffers/CommercialOfferModel";

const initialState: {
  loadingData: boolean;
  page: number;
  pageSize: number;

  commercialOffers?: Array<CommercialOfferModel>;
  total?: number;
} = {
  loadingData: false,

  page: undefined!,
  pageSize: 10,
};

const commercialOffersSlice = createSlice({
  name: "commercialOffers",
  initialState,
  reducers: {
    loadCommercialOffers(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadCommercialOffersSuccess(state, action: PayloadAction<{ commercialOffers?: Array<CommercialOfferModel>; total: number }>) {
      const { commercialOffers, total } = action.payload;

      state.loadingData = false;
      state.commercialOffers = commercialOffers;
      state.total = total;
    },

    changePage(state, action: PayloadAction<number>) {
      state.loadingData = true;
      state.page = action.payload;
    },

    updateCommercialOffer(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<Omit<CommercialOfferModel, "creationDate">>,
    ) {
      state.loadingData = true;
    },
    updateCommercialOfferSuccess(state, action: PayloadAction<CommercialOfferModel>) {
      state.loadingData = false;
      const index = state.commercialOffers?.findIndex((x) => x.id === action.payload.id);
      if (index && state.commercialOffers) {
        state.commercialOffers[index] = action.payload;
      }
    },
  },
});

export default commercialOffersSlice;
