import {
  GITHUB_API_CONFIG,
  AUTH_API_CONFIG,
  DICTIONARY_API_CONFIG,
  ROUTE_API_CONFIG,
  BACK_OFFICE_API_CONFIG,
  DOC_API_CONFIG,
  CHAT_API_CONFIG,
  NOTIFICATION_API_CONFIG,
  AUDIT_API_CONFIG,
} from "./apiServerConfig";

const githubApi = `${GITHUB_API_CONFIG.API_SERVER_SCHEMA}//${GITHUB_API_CONFIG.API_SERVER_HOST}`;
const authApi = `${AUTH_API_CONFIG.API_SERVER_SCHEMA}//${AUTH_API_CONFIG.API_SERVER_HOST}`;
const dictionaryApi = `${DICTIONARY_API_CONFIG.API_SERVER_SCHEMA}//${DICTIONARY_API_CONFIG.API_SERVER_HOST}`;
const routeApi = `${ROUTE_API_CONFIG.API_SERVER_SCHEMA}//${ROUTE_API_CONFIG.API_SERVER_HOST}`;
const backOfficeApi = `${BACK_OFFICE_API_CONFIG.API_SERVER_SCHEMA}//${BACK_OFFICE_API_CONFIG.API_SERVER_HOST}`;
const chatApi = `${CHAT_API_CONFIG.API_SERVER_SCHEMA}//${CHAT_API_CONFIG.API_SERVER_HOST}`;
const docApi = `${DOC_API_CONFIG.API_SERVER_SCHEMA}//${DOC_API_CONFIG.API_SERVER_HOST}`;
const notificationApi = `${NOTIFICATION_API_CONFIG.API_SERVER_SCHEMA}//${NOTIFICATION_API_CONFIG.API_SERVER_HOST}`;
const auditApi = `${AUDIT_API_CONFIG.API_SERVER_SCHEMA}//${AUDIT_API_CONFIG.API_SERVER_HOST}`;

export const SERVER_ADDRESSES = {
  GITHUB_API: githubApi,
  AUTH_API: authApi,
  DICTIONARY_API: dictionaryApi,
  ROUTE_API: routeApi,
  BACK_OFFICE_API: backOfficeApi,
  CHAT_API: chatApi,
  DOC_API: docApi,
  NOTIFICATION_API: notificationApi,
  AUDIT_API: auditApi,
};

export const SERVER_APPLICATION_NAMES = {
  GITHUB_API: GITHUB_API_CONFIG.API_NAME,
  AUTH_API: AUTH_API_CONFIG.API_NAME,
  DICTIONARY_API: DICTIONARY_API_CONFIG.API_NAME,
  ROUTE_API: ROUTE_API_CONFIG.API_NAME,
  BACK_OFFICE_API: BACK_OFFICE_API_CONFIG.API_NAME,
  CHAT_API: CHAT_API_CONFIG.API_NAME,
  DOC_API: DOC_API_CONFIG.API_NAME,
  NOTIFICATION_API: NOTIFICATION_API_CONFIG.API_NAME,
  AUDIT_API: AUDIT_API_CONFIG.API_NAME,
};
