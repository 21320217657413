export enum USERS_TAB_ENUM {
  USERS = "USERS",
  ROLES = "ROLES",
}

const USERS_TAB_TAB_DATA = [
  {
    key: USERS_TAB_ENUM.USERS,
    urlSegment: "internal",
  },
  {
    key: USERS_TAB_ENUM.ROLES,
    urlSegment: "roles",
  },
];

export default USERS_TAB_TAB_DATA;
