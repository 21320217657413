import styled from "styled-components";

import { H1Props, headingStyles } from "./H1";

interface H4Props extends H1Props {}

const H4 = styled.h4<H4Props>`
  font-size: 20px;
  line-height: 135%;

  ${headingStyles};
`;

export default H4;
