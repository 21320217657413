import React from "react";

import { registerLocale } from "react-datepicker";
import { ru as dateFnsRu } from "date-fns/locale/ru";
import { enGB as dateFnsEn } from "date-fns/locale/en-GB";
import { zhCN as dateFnsCn } from "date-fns/locale/zh-CN";

import dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/en-gb";
import "dayjs/locale/zh-cn";

// eslint-disable-next-line import/no-extraneous-dependencies
import "moment/locale/ru";
// eslint-disable-next-line import/no-extraneous-dependencies
import "moment/locale/en-gb";
// eslint-disable-next-line import/no-extraneous-dependencies
import "moment/locale/zh-cn";

import { Locale } from "antd/lib/locale-provider";
import antdLocaleRu from "antd/es/locale/ru_RU";
import antdLocaleEn from "antd/es/locale/en_GB";
import antdLocaleCn from "antd/es/locale/zh_CN";

import { useTranslation } from "react-i18next";

import { ConfigProvider } from "antd";

import { useDispatch } from "react-redux";
import App from "./containers/App";
import globalSlice from "./containers/App/reducer";
import exhaustiveCheck from "./utils/exhaustiveCheck";
import { SupportedLanguages } from "./viewModels/Settings/TranslationModel";

registerLocale("ru", dateFnsRu);
registerLocale("en", dateFnsEn);
registerLocale("zh", dateFnsCn);

// Пришлось замутить кастылек, чтобы засетать в стор язык после кго детекта, но чтобы спиннер был нужного цвета
function LocaleProvider() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const [antdLocale, setAntdLocale] = React.useState<Locale>();

  React.useEffect(() => {
    const lang = i18n.resolvedLanguage as SupportedLanguages;

    dispatch(globalSlice.actions.setLanguage(lang));

    switch (lang) {
      case "ru":
        setAntdLocale(antdLocaleRu);
        dayjs.locale("ru");
        break;
      case "en":
        setAntdLocale(antdLocaleEn);
        dayjs.locale("en-gb");
        break;
      case "zh":
        setAntdLocale(antdLocaleCn);
        dayjs.locale("zh-cn");
        break;
      default:
        exhaustiveCheck(lang);
    }
  }, [i18n.resolvedLanguage, dispatch]);

  return (
    <ConfigProvider locale={antdLocale}>
      <App />
    </ConfigProvider>
  );
}

export default LocaleProvider;
