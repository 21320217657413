import styled from "styled-components";

import { H1Props, headingStyles } from "./H1";

interface H6Props extends H1Props {}

const H6 = styled.h6<H6Props>`
  font-size: 16px;
  line-height: 20px;

  ${headingStyles};
`;

export default H6;
