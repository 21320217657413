import LocalizableEntityModel from "../viewModels/General/LocalizableEntityModel";
import { SupportedLanguages } from "../viewModels/Settings/TranslationModel";

export default (entity: LocalizableEntityModel, lang: SupportedLanguages): string => {
  switch (lang) {
    case "ru":
      return entity.name;
    case "en":
      return entity.englishName || entity.name;
    case "zh":
      return entity.chineseName || entity.name;
    default:
      return entity.name;
  }
};
