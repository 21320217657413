import styled from "styled-components";

import { H1Props, headingStyles } from "./H1";

interface H2Props extends H1Props {}

const H2 = styled.h2<H2Props>`
  font-size: 32px;
  line-height: 40px;

  ${headingStyles};
`;

export default H2;
