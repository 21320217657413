import { MessageConfig } from "../../reducer/types";

const MessagesConfig = {
  updateProfileSuccess: {
    key: "message_update_profile_success",
    type: "success",
  },
  changesSaved: {
    key: "message_changes_saved",
    type: "success",
  },
  changesNotSaved: {
    key: "message_changes_not_saved",
    type: "error",
  },
  saveTemplateSuccess: {
    key: "message_save_template_success",
    type: "success",
  },
  saveTemplateError: {
    key: "message_save_template_error",
    type: "error",
  },
  deleteTemplateSuccess: {
    key: "message_delete_template_success",
    type: "success",
  },
  deleteTemplateError: {
    key: "message_delete_template_error",
    type: "error",
  },
  updateTemplateSuccess: {
    key: "message_update_template_success",
    type: "success",
  },
  updateTemplateError: {
    key: "message_update_template_error",
    type: "error",
  },
  sendFeedbackFormSuccess: {
    key: "message_send_feedback_form_success",
    type: "success",
  },
  calculateRoutesError: {
    key: "message_calculate_routes_error",
    type: "error",
  },
  orderEmailSended: {
    key: "message_order_email_sended",
    type: "success",
  },
  applicationRequest: {
    key: "message_application_request",
    type: "success",
  },
  applicationOnConfirmation: {
    key: "message_application_on_confirmation",
    type: "success",
  },
  applicationRejected: {
    key: "message_application_rejected",
    type: "success",
  },
  applicationCalculated: {
    key: "message_application_calculated",
    type: "success",
  },
  applicationInvoiced: {
    key: "message_application_invoiced",
    type: "success",
  },
  applicationDelivered: {
    key: "message_application_delivered",
    type: "success",
  },
  applicationClaimed: {
    key: "message_application_claimed",
    type: "success",
  },
  applicationCompleted: {
    key: "message_application_completed",
    type: "success",
  },
  applicationRevoked: {
    key: "message_application_revoked",
    type: "success",
  },
  applicationProcessed: {
    key: "message_application_processed",
    type: "success",
  },
  applicationBooked: {
    key: "message_application_booked",
    type: "success",
  },
  applicationInProcess: {
    key: "message_application_in_process",
    type: "success",
  },
  internalUserCreated: {
    key: "message_internal_user_created",
    type: "success",
  },
  internalUserUpdated: {
    key: "message_internal_user_updated",
    type: "success",
  },
  roleCreated: {
    key: "message_role_created",
    type: "success",
  },
  roleUpdated: {
    key: "message_role_updated",
    type: "success",
  },
  transporterCreated: {
    key: "message_transporter_created",
    type: "success",
  },
  transporterUpdated: {
    key: "message_transporter_updated",
    type: "success",
  },
  userMarkApplicationsAsRequiringAttentionSuccess: {
    key: "message_user_mark_applications_as_requiring_attention_success",
    type: "success",
  },
  userMarkApplicationsAsRequiringAttentionReject: {
    key: "message_user_mark_applications_as_requiring_attention_reject",
    type: "error",
  },
  coefficientUpdated: {
    key: "message_coefficient_updated",
    type: "success",
  },
  routeCreated: {
    key: "message_route_created",
    type: "success",
  },
  routeCreateError: {
    key: "message_route_create_error",
    type: "error",
  },
  routeExist: {
    key: "message_route_exist",
    type: "error",
  },
  routeUpdated: {
    key: "message_route_updated",
    type: "success",
  },
  routeUpdateError: {
    key: "message_route_update_error",
    type: "error",
  },
  exportRoutesSuccess: {
    key: "message_export_routes_success",
    type: "success",
  },
  exportRoutesError: {
    key: "message_export_routes_error",
    type: "error",
  },
  countryCreated: {
    key: "message_country_created",
    type: "success",
  },
  countryUpdated: {
    key: "message_country_updated",
    type: "success",
  },
  locationCreated: {
    key: "message_location_created",
    type: "success",
  },
  locationCreatedError: {
    key: "message_location_created_error",
    type: "error",
  },
  locationsLoadReject: {
    key: "message_locations_load_reject",
    type: "error",
  },
  routesLoadReject: {
    key: "message_routes_load_reject",
    type: "error",
  },
  currenciesLoadReject: {
    key: "message_currencies_load_reject",
    type: "error",
  },
  countriesLoadReject: {
    key: "message_countries_load_reject",
    type: "error",
  },
  countryUpdateReject: {
    key: "message_country_create_reject",
    type: "error",
  },
  countryCreateReject: {
    key: "message_countries_update_reject",
    type: "error",
  },
  coefficientsLoadReject: {
    key: "message_coefficients_load_reject",
    type: "error",
  },
  coefficientUpdateReject: {
    key: "message_coefficient_update_reject",
    type: "error",
  },
  applicationServicesLoadReject: {
    key: "message_application_services_load_reject",
    type: "error",
  },
  applicationServiceUpdated: {
    key: "message_application_service_updated",
    type: "success",
  },
  applicationServiceUpdateReject: {
    key: "message_application_service_update_reject",
    type: "error",
  },
  passwordUpdateSuccess: {
    key: "message_password_update_success",
    type: "success",
  },
  passwordUpdateReject: {
    key: "message_password_update_reject",
    type: "error",
  },

  usersLoadError: {
    key: "message_users_load_error",
    type: "error",
  },
  userCreateSuccess: {
    key: "message_user_create_success",
    type: "success",
  },
  userCreateError: {
    key: "message_user_create_error",
    type: "error",
  },
  userUpdateSuccess: {
    key: "message_user_update_success",
    type: "success",
  },
  userUpdateError: {
    key: "message_user_update_error",
    type: "error",
  },

  rolesLoadError: {
    key: "message_roles_load_error",
    type: "error",
  },
  roleCreateSuccess: {
    key: "message_role_create_success",
    type: "success",
  },
  roleCreateError: {
    key: "message_role_create_error",
    type: "error",
  },
  roleUpdateSuccess: {
    key: "message_role_create_success",
    type: "success",
  },
  roleUpdateError: {
    key: "message_role_create_error",
    type: "error",
  },
  assignUserToApplicationSuccess: {
    key: "message_assign_user_to_application_success",
    type: "success",
  },
  assignUserToApplicationError: {
    key: "message_assign_user_to_application_error",
    type: "error",
  },

  auditLogLoadError: {
    key: "message_audit_log_load_error",
    type: "error",
  },

  documentUploadSuccess: {
    key: "message_document_upload_success",
    type: "success",
  },
  documentUploadError: {
    key: "message_document_upload_error",
    type: "error",
  },

  importRoutesError: {
    key: "message_import_routes_error",
    type: "error",
  },
  importRoutesSuccess: {
    key: "message_import_routes_success",
    type: "success",
  },
  importRoutesSuccessWithErrors: {
    key: "message_import_routes_success_with_errors",
    type: "success",
  },

  generateNewPasswordSuccess: {
    key: "message_generate_new_password_success",
    type: "success",
  },
  generateNewPasswordError: {
    key: "message_generate_new_password_error",
    type: "error",
  },

  markForDeletionSuccess: {
    key: "message_mark_for_deletion_success",
    type: "success",
  },
  markForDeletionError: {
    key: "message_mark_for_deletion_error",
    type: "error",
  },

  unmarkForDeletionSuccess: {
    key: "message_unmark_for_deletion_success",
    type: "success",
  },
  unmarkForDeletionError: {
    key: "message_unmark_for_deletion_error",
    type: "error",
  },
  rateUpdateRoutesLoadReject: {
    key: "message_rate_update_load_load_reject",
    type: "error",
  },
  loadApplicationError: {
    key: "message_load_application_error",
    type: "error",
  },
  deleteDocumentError: {
    key: "message_delete_document_error",
    type: "error",
  },
  applicationServiceCreateSuccess: {
    key: "message_application_service_create_success",
    type: "success",
  },
  applicationServiceCreateError: {
    key: "message_application_service_create_error",
    type: "error",
  },
  applicationServiceDeleteSuccess: {
    key: "message_application_service_delete_success",
    type: "success",
  },
  applicationServiceDeleteError: {
    key: "message_application_service_deleteerror",
    type: "error",
  },
};

const typedMessagesConfig = MessagesConfig as { [key in keyof typeof MessagesConfig]: MessageConfig };

export { typedMessagesConfig as MessagesConfig };
