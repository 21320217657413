import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { USERS_TAB_ENUM } from "../constants";

import {
  ILogistDictionariesUsersPageState,
  LoadedLogistDictionariesInternalUsersPayloadType,
  LoadedLogistDictionariesRolesPayloadType,
  SortObject,
} from "./types";
import { CreateDictionariesRoleModel, UpdateDictionariesRoleModel } from "../../../../viewModels/Dictionaries/DictionariesRoleModel";
import { BasicUserRoleModel } from "../../../../viewModels/User/UserInfoModel";
import { FormErrorModel } from "../../../../viewModels/General/ErrorModel";
import { CreateDictionariesUserModel, UpdateDictionariesUserModel } from "../../../../viewModels/Dictionaries/DictionariesUserModel";

const initialState: ILogistDictionariesUsersPageState = {
  loading: true,
  loadingData: true,

  activeUsersTabId: undefined!,

  internalUsers: undefined!,
  roles: undefined!,

  total: undefined!,
  pageSize: 10,
  page: 1,

  sort: undefined!,
  searchFilterValue: "",

  internalUserIdToEdit: undefined!,
  isEditInternalUserDrawerLoading: false,
  isEditInternalUserDrawerOpen: false,
  createUserFormErrors: undefined!,

  externalUserIdToEdit: undefined!,
  isEditExternalUserDrawerLoading: false,
  isEditExternalUserDrawerOpen: false,

  roleIdToEdit: undefined!,
  isEditRoleDrawerLoading: false,
  isEditRoleDrawerOpen: false,

  rolesBasicInfo: undefined!,
};

const dictionariesUsersSlice = createSlice({
  name: "dictionariesUsers",
  initialState,
  reducers: {
    loadInternalUsers(state) {
      state.loadingData = true;
      state.activeUsersTabId = USERS_TAB_ENUM.USERS;
      state.page = 1;
    },
    loadRoles(state) {
      state.loadingData = true;
      state.activeUsersTabId = USERS_TAB_ENUM.ROLES;
      state.page = 1;
    },
    setLoadedInternalUsers(state, action: PayloadAction<LoadedLogistDictionariesInternalUsersPayloadType>) {
      const { users, total } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.internalUsers = users;
      state.total = total;
    },
    setLoadedRoles(state, action: PayloadAction<LoadedLogistDictionariesRolesPayloadType>) {
      const { roles, total } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.roles = roles;
      state.total = total;
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;
      state.loadingData = true;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.loadingData = true;
    },
    sortUsers(state, action: PayloadAction<SortObject>) {
      state.sort = {
        key: action.payload.key,
        order: action.payload.order,
      };
      state.loadingData = true;
    },
    changeSearchFilterValue(state, action: PayloadAction<string>) {
      state.searchFilterValue = action.payload;
    },
    search(state) {
      state.loadingData = true;
      state.page = 1;
    },
    openEditInternalUserDrawer(state, action: PayloadAction<number | undefined>) {
      state.internalUserIdToEdit = action.payload!;
      state.isEditInternalUserDrawerOpen = true;
    },
    closeEditInternalUserDrawer(state) {
      if (!state.isEditInternalUserDrawerLoading) {
        state.internalUserIdToEdit = undefined!;
        state.isEditInternalUserDrawerOpen = false;
      }
    },

    // eslint-disable-next-line no-unused-vars
    createUser(state, action: PayloadAction<CreateDictionariesUserModel>) {
      state.isEditInternalUserDrawerLoading = true;
    },
    createUserSuccess(state) {
      state.isEditInternalUserDrawerLoading = false;
      state.isEditInternalUserDrawerOpen = false;
    },
    createUserError(state, action: PayloadAction<any>) {
      if (action.payload.find((error: FormErrorModel) => error.field === "email")) {
        state.createUserFormErrors = action.payload;
      } else {
        state.isEditInternalUserDrawerOpen = false;
      }
      state.isEditInternalUserDrawerLoading = false;
    },

    // eslint-disable-next-line no-unused-vars
    updateUser(state, action: PayloadAction<UpdateDictionariesUserModel>) {
      state.isEditInternalUserDrawerLoading = true;
    },
    updateUserSuccess(state) {
      state.isEditInternalUserDrawerLoading = false;
      state.isEditInternalUserDrawerOpen = false;
    },
    updateUserError(state) {
      state.isEditInternalUserDrawerLoading = false;
      state.isEditInternalUserDrawerOpen = false;
    },
    // eslint-disable-next-line no-unused-vars
    resetUserPassword(state, action: PayloadAction<number>) {
      state.isEditInternalUserDrawerLoading = true;
    },
    resetUserPasswordSuccess(state) {
      state.isEditInternalUserDrawerLoading = false;
    },
    resetUserPasswordError(state) {
      state.isEditInternalUserDrawerLoading = false;
    },

    openEditExternalUserDrawer(state, action: PayloadAction<number | undefined>) {
      state.externalUserIdToEdit = action.payload!;
      state.isEditExternalUserDrawerOpen = true;
    },
    closeEditExternalUserDrawer(state) {
      if (!state.isEditExternalUserDrawerLoading) {
        state.externalUserIdToEdit = undefined!;
        state.isEditExternalUserDrawerOpen = false;
      }
    },
    openEditRoleDrawer(state, action: PayloadAction<number | undefined>) {
      state.roleIdToEdit = action.payload!;
      state.isEditRoleDrawerOpen = true;
    },
    closeEditRoleDrawer(state) {
      if (!state.isEditRoleDrawerLoading) {
        state.roleIdToEdit = undefined!;
        state.isEditRoleDrawerOpen = false;
      }
    },

    // eslint-disable-next-line no-unused-vars
    createRole(state, action: PayloadAction<CreateDictionariesRoleModel>) {
      state.isEditRoleDrawerLoading = true;
    },
    createRoleSuccess(state) {
      state.isEditRoleDrawerLoading = false;
      state.isEditRoleDrawerOpen = false;
    },
    createRoleError(state) {
      state.isEditRoleDrawerLoading = false;
      state.isEditRoleDrawerOpen = false;
    },

    // eslint-disable-next-line no-unused-vars
    updateRole(state, action: PayloadAction<UpdateDictionariesRoleModel>) {
      state.isEditRoleDrawerLoading = true;
    },
    updateRoleSuccess(state) {
      state.isEditRoleDrawerLoading = false;
      state.isEditRoleDrawerOpen = false;
    },
    updateRoleError(state) {
      state.isEditRoleDrawerLoading = false;
      state.isEditRoleDrawerOpen = false;
    },

    changeActiveUsersTabId(state, action: PayloadAction<string>) {
      state.activeUsersTabId = action.payload;
    },

    loadRolesBasicInfo() {},
    loadRolesBasicInfoSuccess(state, action: PayloadAction<Array<BasicUserRoleModel>>) {
      state.rolesBasicInfo = action.payload;
    },
  },
});

export default dictionariesUsersSlice;
