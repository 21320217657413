import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import LoadingIndicator from "../../components/LoadingIndicator";

import AuthModal from "../AuthModal";
import ContactModal from "../ContactModal";

import WorkspaceLayout from "../Layouts/WorkspaceLayout/loadable";
import PersonalAreaLayout from "../Layouts/PersonalAreaLayout/loadable";

import MessageProvider from "./components/MessageProvider";

import { makeSelectHost, makeSelectInitialLoadingError, makeSelectLoading } from "./selectors";

import GlobalStyles from "../../styled";
import TranslationsPage from "../TranslationsPage/loadable";

function App() {
  const loading = useSelector(makeSelectLoading());
  const error = useSelector(makeSelectInitialLoadingError());
  const host = useSelector(makeSelectHost());

  if (error) {
    return <div>Error....</div>;
  }

  const content = loading ? (
    <LoadingIndicator />
  ) : (
    <>
      <MessageProvider />
      <AuthModal />
      <ContactModal />
      <Routes>
        <Route path="/translations" element={<TranslationsPage />} />
        <Route path="personal-area/*" element={<PersonalAreaLayout />} />
        <Route path="/*" element={<WorkspaceLayout />} />
      </Routes>
    </>
  );

  return (
    <>
      <GlobalStyles host={host} />
      <React.Suspense fallback={<LoadingIndicator />}>{content}</React.Suspense>
    </>
  );
}

export default React.memo(App);
