import { combineReducers, configureStore, createImmutableStateInvariantMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import configureApi from "./api";
import configureAuth from "./auth";

import globalSlice from "./containers/App/reducer";
import authModalSlice from "./containers/AuthModal/reducer";
import homeSlice from "./containers/HomePage/reducer";

import clientProfileSlice from "./containers/PersonalAreaPages/ProfilePages/containers/ClientProfilePage/reducer";
import backOfficeProfileSlice from "./containers/PersonalAreaPages/ProfilePages/containers/BackOfficeProfilePage/reducer";
import securitySlice from "./containers/PersonalAreaPages/ProfilePages/containers/SecurityPage/reducer";

import clientDocumentsSlice from "./containers/PersonalAreaPages/DocumentsPages/containers/ClientDocumentsPage/reducer";

import companyDetailsSlice from "./containers/PersonalAreaPages/CompanyDetailsPage/reducer";

import dictionariesCoefficentsSlice from "./containers/PersonalAreaPages/DictionariesCoefficentsPage/reducer";
import dictionariesCountriesSlice from "./containers/PersonalAreaPages/DictionariesCountriesPage/reducer";
import dictionariesCurrenciesSlice from "./containers/PersonalAreaPages/DictionariesCurrenciesPage/reducer";
import dictionariesLocationsSlice from "./containers/PersonalAreaPages/DictionariesLocationsPage/reducer";
import dictionariesRoutesSlice from "./containers/PersonalAreaPages/DictionariesRoutesPage/reducer";
import dictionariesUsersSlice from "./containers/PersonalAreaPages/DictionariesUsersPage/reducer";
import dictionariesCompaniesSlice from "./containers/PersonalAreaPages/DictionariesCompaniesPage/reducer";
import dictionariesRatesUpdateSlice from "./containers/PersonalAreaPages/DictionariesRatesUpdatePage/reducer";

import auditLogSlice from "./containers/PersonalAreaPages/AuditLogPage/reducer";

import notificationsSlice from "./containers/Layouts/PersonalAreaLayout/reducer";

import globalSaga from "./containers/App/sagas";
import importRoutesSlice from "./containers/PersonalAreaPages/DictionariesRoutesPage/components/ImportRoutesPage/reducer";
import dictionariesRateUpdateRoutesSlice from "./containers/PersonalAreaPages/DictionariesRateUpdateRoutes/reducer";
import dictionariesApplicationServicesSlice from "./containers/PersonalAreaPages/DictionariesApplicationServicesPage/reducer";

import salesHomeSlice from "./containers/SalesPages/SalesHomePage/reducer";
import salesApplicationSlice from "./containers/SalesPages/SalesApplicationPage/reducer";

import logisticianHomeSlice from "./containers/LogisticianPages/LogisticialHomePage/reducer";
import logisticialApplicationSlice from "./containers/LogisticianPages/LogisticialApplicationPage/reducer";
import statisticsSlice from "./containers/PersonalAreaPages/StatisticsPage/reducer";
import applicationCoefficientsSlice from "./containers/PersonalAreaPages/ApplicationCoefficentsPage/reducer";
import translationsSlice from "./containers/TranslationsPage/reducer";
import salesCommercialOfferSlice from "./containers/SalesPages/SalesCommercialOfferPage/reducer";
import salesDealSlice from "./containers/SalesPages/SalesDealPage/reducer";
import commercialOffersSlice from "./containers/CommercialOffers/reducer";
import dealsSlice from "./containers/Deals/reducer";
import logisticianCommercialOfferSlice from "./containers/LogisticianPages/LogisticianCommercialOfferPage/reducer";

export const sagaMiddleware = createSagaMiddleware();

export const initialReducer = {
  [globalSlice.name]: globalSlice.reducer,
  [authModalSlice.name]: authModalSlice.reducer,
  [homeSlice.name]: homeSlice.reducer,

  [clientProfileSlice.name]: clientProfileSlice.reducer,
  [backOfficeProfileSlice.name]: backOfficeProfileSlice.reducer,
  [securitySlice.name]: securitySlice.reducer,

  [clientDocumentsSlice.name]: clientDocumentsSlice.reducer,

  [companyDetailsSlice.name]: companyDetailsSlice.reducer,
  [auditLogSlice.name]: auditLogSlice.reducer,
  [statisticsSlice.name]: statisticsSlice.reducer,

  [dictionariesCoefficentsSlice.name]: dictionariesCoefficentsSlice.reducer,
  [dictionariesCountriesSlice.name]: dictionariesCountriesSlice.reducer,
  [dictionariesCurrenciesSlice.name]: dictionariesCurrenciesSlice.reducer,
  [dictionariesLocationsSlice.name]: dictionariesLocationsSlice.reducer,
  [dictionariesRatesUpdateSlice.name]: dictionariesRatesUpdateSlice.reducer,
  [dictionariesRateUpdateRoutesSlice.name]: dictionariesRateUpdateRoutesSlice.reducer,
  [dictionariesRoutesSlice.name]: dictionariesRoutesSlice.reducer,
  [dictionariesUsersSlice.name]: dictionariesUsersSlice.reducer,
  [dictionariesCompaniesSlice.name]: dictionariesCompaniesSlice.reducer,
  [dictionariesApplicationServicesSlice.name]: dictionariesApplicationServicesSlice.reducer,

  [importRoutesSlice.name]: importRoutesSlice.reducer,

  [notificationsSlice.name]: notificationsSlice.reducer,

  [salesHomeSlice.name]: salesHomeSlice.reducer,
  [salesApplicationSlice.name]: salesApplicationSlice.reducer,
  [salesCommercialOfferSlice.name]: salesCommercialOfferSlice.reducer,
  [salesDealSlice.name]: salesDealSlice.reducer,

  [logisticianHomeSlice.name]: logisticianHomeSlice.reducer,
  [logisticialApplicationSlice.name]: logisticialApplicationSlice.reducer,
  [logisticianCommercialOfferSlice.name]: logisticianCommercialOfferSlice.reducer,

  [applicationCoefficientsSlice.name]: applicationCoefficientsSlice.reducer,

  [translationsSlice.name]: translationsSlice.reducer,

  [commercialOffersSlice.name]: commercialOffersSlice.reducer,
  [dealsSlice.name]: dealsSlice.reducer,
};

const reducer = combineReducers(initialReducer);

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware();

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: false,
    }).concat(sagaMiddleware, immutableInvariantMiddleware),
});

configureApi(sagaMiddleware);
configureAuth(sagaMiddleware);

sagaMiddleware.run(globalSaga as any);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
