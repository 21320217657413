import React, { useCallback } from "react";

import { Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import LoginView from "./components/LoginView";
import RestorePasswordView from "./components/RestorePasswordView";
import RegisterView from "./components/RegisterView";
import SuccessRegisterView from "./components/SuccessRegisterView";
import SucessRestorePasswordView from "./components/SucessRestorePasswordView";

import { makeSelectIsAuthModalVisible } from "../App/selectors";
import AppSlice from "../App/reducer";

import { makeSelectCurrentView } from "./selectors";

function AuthModal() {
  const dispatch = useDispatch();

  const currentView = useSelector(makeSelectCurrentView());
  const isAuthModalVisible = useSelector(makeSelectIsAuthModalVisible());

  const handleCloseAuthModal = useCallback(() => {
    dispatch(AppSlice.actions.closeAuthModal());
  }, [dispatch]);

  return (
    <Modal
      open={isAuthModalVisible}
      onCancel={handleCloseAuthModal}
      footer={null}
      destroyOnClose
      closeIcon={<CloseCircleOutlined />}
      width={545}
      bodyStyle={{ padding: "50px 80px" }}
    >
      {currentView === "LOGIN_VIEW" && <LoginView />}
      {currentView === "RESTORE_PASSWORD_VIEW" && <RestorePasswordView />}
      {currentView === "REGISTER_VIEW" && <RegisterView />}
      {currentView === "SUCCESS_REGISTER_VIEW" && <SuccessRegisterView />}
      {currentView === "SUCCESS_RESTORE_PASSWORD_VIEW" && <SucessRestorePasswordView />}
    </Modal>
  );
}

export default React.memo(AuthModal);
