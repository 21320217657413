/* eslint-disable no-lonely-if */
import React, { useCallback } from "react";

import { Input, InputProps, InputRef } from "antd";

import ControlLabel from "../../ControlLabel";
import { integerRegExp, numbersRegExp, numberWithDotAndCommaRegExp } from "./constants";
import { Hint } from "./styled";
import formatPrice from "../../../utils/formatPrice";

interface MyInputProps extends InputProps {
  value?: string;
  label?: string;
  mandatory?: boolean;
  type?: "only-numbers" | "interger" | "double";
  maxValue?: number;
  status?: "error" | "warning";
  isShowHint?: boolean;
  hint?: string | null;
  ref?: React.RefObject<InputRef>;
  isPricingValue?: boolean;
}

const MyInput = React.forwardRef(
  (
    { label, mandatory, onChange, type, value, maxValue, isShowHint, status, hint, isPricingValue, ...props }: MyInputProps,
    ref: React.Ref<InputRef>,
  ) => {
    const changeHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(event);
        }
      },
      [onChange],
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (type === "only-numbers") {
          if (integerRegExp.test(event.target.value)) {
            if (maxValue) {
              if (+event.target.value <= maxValue) {
                changeHandler(event);
              }
            } else {
              changeHandler(event);
            }
          } else {
            if (!value) {
              changeHandler({ ...event, target: { ...event.target, value: "" } });
            }
          }
        } else if (type === "interger") {
          const number = event.target.value.replace(numbersRegExp, "");

          if (integerRegExp.test(number)) {
            const newValue = number === "" ? "" : +number;
            if (maxValue) {
              if (+number <= maxValue) {
                changeHandler({ ...event, target: { ...event.target, value: `${newValue}` } });
              }
            } else {
              changeHandler({ ...event, target: { ...event.target, value: `${newValue}` } });
            }
          } else {
            if (!value) {
              changeHandler({ ...event, target: { ...event.target, value: "" } });
            }
          }
        } else if (type === "double") {
          let floatStringValue = event.target.value.replace(numberWithDotAndCommaRegExp, "").replaceAll(",", ".");
          const number = floatStringValue.replaceAll(" ", "");

          if (number[0] === "0" && +number >= 1) {
            floatStringValue = floatStringValue.substring(1);
          }

          if (floatStringValue.length === 1 && floatStringValue[0] === ".") {
            floatStringValue = "0.";
          }

          const dotsIndexes = [];
          for (let i = 0; i < floatStringValue.length; i += 1) {
            if (floatStringValue[i] === ".") dotsIndexes.push(i);
          }

          if (dotsIndexes.length === 2) {
            floatStringValue = floatStringValue.slice(0, dotsIndexes[1]) + floatStringValue.slice(dotsIndexes[1] + 1);
            return;
          }

          if (dotsIndexes.length === 1 && floatStringValue.length > dotsIndexes[0] + 3) {
            return;
          }

          let pricingValue = floatStringValue ? formatPrice(+floatStringValue) : "";
          if (floatStringValue && floatStringValue.slice(-1) === ".") {
            pricingValue += ".";
          }

          if (maxValue) {
            if (+floatStringValue <= maxValue) {
              changeHandler({ ...event, target: { ...event.target, value: isPricingValue ? pricingValue : floatStringValue } });
            }
          } else {
            changeHandler({ ...event, target: { ...event.target, value: isPricingValue ? pricingValue : floatStringValue } });
          }
        } else {
          changeHandler(event);
        }
      },
      [type, value, maxValue, changeHandler, isPricingValue],
    );

    return (
      <div style={{ display: "flex", flexDirection: "column-reverse" }}>
        {isShowHint && <Hint>{hint}</Hint>}
        <Input {...props} value={value} onChange={handleChange} status={isShowHint ? status : ""} ref={ref} />
        {label && (
          <ControlLabel>
            {label}
            {mandatory && "*"}
          </ControlLabel>
        )}
      </div>
    );
  },
);

export default React.memo(MyInput);
