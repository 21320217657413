import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LoadedDocumentsPayloadType, IClientDocumentsState, LoadFilePayloadType } from "./types";

const initialState: IClientDocumentsState = {
  loading: true,
  loadingData: true,
  documents: undefined!,

  pageSize: 10,
  page: 1,
  total: undefined!,

  isFileModalOpen: false,
  isFileLoading: false,
  file: undefined!,
  filename: "",
  typeId: 0,
};

const clientDocumentsSlice = createSlice({
  name: "clientDocuments",
  initialState,
  reducers: {
    loadDocuments(state) {
      state.loadingData = true;
    },
    loadDocumentsSuccess(state, action: PayloadAction<LoadedDocumentsPayloadType>) {
      const { documents, page, pageSize, count } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.documents = documents;
      state.page = page;
      state.pageSize = pageSize;
      state.total = count;
      state.isFileLoading = false;
      state.isFileModalOpen = false;
      state.file = undefined!;
      state.filename = "";
      state.typeId = 0;
    },
    loadDocumentsError(state) {
      state.loading = false;
      state.loadingData = false;
    },
    uploadDocument(state, action: PayloadAction<LoadFilePayloadType>) {
      const { file, filename, typeId } = action.payload;
      state.isFileLoading = true;
      state.file = file;
      state.filename = filename;
      state.typeId = typeId;
    },
    uploadDocumentError(state) {
      state.isFileLoading = false;
    },
    openFileModal(state) {
      state.isFileModalOpen = true;
    },
    closeFileModal(state) {
      state.isFileModalOpen = false;
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;
      state.loadingData = true;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.loadingData = true;
    },
    // eslint-disable-next-line
    downloadDocument(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },
    downloadDocumentSuccess(state) {
      state.loadingData = false;
    },
    downloadDocumentError(state) {
      state.loadingData = false;
    },
    // eslint-disable-next-line
    deleteDocument(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },
    deleteDocumentError(state) {
      state.loadingData = false;
    },
  },
});

export default clientDocumentsSlice;
