import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import UserProfileModel from "../../../../../../viewModels/User/UserProfileModel";

import { IBackOfficeProfilePageState } from "./types";
import { RoleReferenceModel } from "../../../../../../viewModels/Role/RoleReferenceModel";
import { FormErrorModel } from "../../../../../../viewModels/General/ErrorModel";

const initialState: IBackOfficeProfilePageState = {
  loading: true,
  loadingData: true,
  backOfficeProfile: undefined!,
  isUpdateBackOfficeProfileLoading: undefined!,
  role: undefined!,
  profileFormErrors: undefined!,
};

const backOfficeProfilePageSlice = createSlice({
  name: "backOfficeProfile",
  initialState,
  reducers: {
    loadBackOfficeProfile(state) {
      state.loadingData = true;
    },
    loadBackOfficeProfileSuccess(state, action: PayloadAction<UserProfileModel>) {
      state.loading = false;
      state.loadingData = false;
      state.backOfficeProfile = action.payload;
    },
    loadBackOfficeProfileError(state) {
      state.loading = false;
      state.loadingData = false;
    },
    // eslint-disable-next-line no-unused-vars
    updateBackOfficeProfile(state, action: PayloadAction<UserProfileModel>) {
      state.isUpdateBackOfficeProfileLoading = true;
    },
    updateBackOfficeProfileSuccess(state, action: PayloadAction<UserProfileModel>) {
      state.isUpdateBackOfficeProfileLoading = false;
      state.backOfficeProfile = action.payload;
    },
    updateBackOfficeProfileError(state, action: PayloadAction<Array<any>>) {
      if (action.payload.find((error: FormErrorModel) => error.field === "email")) {
        state.profileFormErrors = action.payload;
      }

      state.isUpdateBackOfficeProfileLoading = false;
    },

    loadRoleSuccess(state, action: PayloadAction<RoleReferenceModel>) {
      state.role = action.payload.name;
    },
  },
});

export default backOfficeProfilePageSlice;
