import React, { useCallback } from "react";

import { Col, Row } from "antd";

import Input from "../MyInput";

import { RangeNumberInputProps } from "./types";

function RangeNumberInput({ fromPlaceholder, toPlaceholder, fromValue, toValue, onChange }: RangeNumberInputProps) {
  const handleChangeFrom = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value, toValue);
    },
    [onChange, toValue],
  );

  const handleChangeTo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(fromValue, e.target.value);
    },
    [onChange, fromValue],
  );

  return (
    <Row gutter={20}>
      <Col span={12}>
        <Input placeholder={fromPlaceholder} type="interger" onChange={handleChangeFrom} value={fromValue} />
      </Col>
      <Col span={12}>
        <Input placeholder={toPlaceholder} type="interger" onChange={handleChangeTo} value={toValue} />
      </Col>
    </Row>
  );
}

export default React.memo(RangeNumberInput);
