import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import CommercialOfferModel from "../../../viewModels/CommercialOffers/CommercialOfferModel";

const initialState: {
  loading: boolean;
  loadingData: boolean;

  commercialOffer: CommercialOfferModel;
} = {
  loading: true,
  loadingData: true,

  commercialOffer: undefined!,
};

const salesCommercialOfferSlice = createSlice({
  name: "salesCommercialOffer",
  initialState,
  reducers: {
    loadCommercialOffer(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<number>,
    ) {
      state.loadingData = true;
    },
    loadCommercialOfferSuccess(state, action: PayloadAction<CommercialOfferModel>) {
      state.loading = false;
      state.loadingData = false;
      state.commercialOffer = action.payload;
    },

    resetState: () => initialState,

    updateCommercialOffer(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<Omit<CommercialOfferModel, "creationDate" | "application">>,
    ) {
      state.loadingData = true;
    },
    updateCommercialOfferSuccess(state, action: PayloadAction<CommercialOfferModel>) {
      state.loadingData = false;
      state.commercialOffer = action.payload;
    },
    createDocument(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<number>,
    ) {
      state.loadingData = true;
    },
    createDocumentSuccess(state) {
      state.loadingData = false;
    },
    createDeal(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<number>,
    ) {
      state.loadingData = true;
    },
    createDealSuccess(state) {
      state.loadingData = false;
    },
  },
});

export default salesCommercialOfferSlice;
