import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IDictionariesRateUpdateRoutesPageState, LoadedDictionariesRateUpdateRoutesPayloadType } from "./types";

const initialState: IDictionariesRateUpdateRoutesPageState = {
  loading: true,
  loadingData: true,

  id: undefined!,

  routes: undefined!,
  carriers: undefined!,
  locations: undefined!,

  total: undefined!,
  pageSize: 10,
  page: 1,
};

const dictionariesRateUpdateRoutesSlice = createSlice({
  name: "dictionariesRateUpdateRoutes",
  initialState,
  reducers: {
    loadRateUpdateRoutes(state, action: PayloadAction<number>) {
      state.loadingData = true;
      state.page = 1;
      state.id = action.payload;
    },
    loadRateUpdateRoutesSuccess(state, action: PayloadAction<LoadedDictionariesRateUpdateRoutesPayloadType>) {
      const { routes, total, carriers, locations } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.routes = routes;
      state.total = total;
      state.locations = locations;
      state.carriers = carriers;
    },
    loadRateUpdateRoutesError(state) {
      state.loading = false;
      state.loadingData = false;
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;
      state.loadingData = true;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.loadingData = true;
    },
  },
});

export default dictionariesRateUpdateRoutesSlice;
