enum USER_PERMISSIONS {
  DICTIONARY_COUNTRY_VIEW = "DICTIONARY_COUNTRY_VIEW",
  DICTIONARY_COUNTRY_EDIT = "DICTIONARY_COUNTRY_EDIT",
  DICTIONARY_LOCATION_VIEW = "DICTIONARY_LOCATION_VIEW",
  DICTIONARY_LOCATION_EDIT = "DICTIONARY_LOCATION_EDIT",
  DICTIONARY_COEFFICIENT_VIEW = "DICTIONARY_COEFFICIENT_VIEW",
  DICTIONARY_COEFFICIENT_EDIT = "DICTIONARY_COEFFICIENT_EDIT",
  DICTIONARY_CURRENCY_VIEW = "DICTIONARY_CURRENCY_VIEW",
  DICTIONARY_USER_EXTERNAL_VIEW = "DICTIONARY_USER_EXTERNAL_VIEW",
  DICTIONARY_USER_EXTERNAL_EDIT = "DICTIONARY_USER_EXTERNAL_EDIT",
  DICTIONARY_USER_EXTERNAL_MANAGE_DOCUMENTS = "DICTIONARY_USER_EXTERNAL_MANAGE_DOCUMENTS",
  DICTIONARY_USER_INTERNAL_VIEW = "DICTIONARY_USER_INTERNAL_VIEW",
  DICTIONARY_USER_INTERNAL_EDIT = "DICTIONARY_USER_INTERNAL_EDIT",
  DICTIONARY_ROTE_VIEW = "DICTIONARY_ROTE_VIEW",
  DICTIONARY_ROTE_EDIT = "DICTIONARY_ROTE_EDIT",
  DICTIONARY_ORDER_ASSIGN = "DICTIONARY_ORDER_ASSIGN",
  DICTIONARY_ORDER_VIEW = "DICTIONARY_ORDER_VIEW",
  DICTIONARY_ORDER_EDIT = "DICTIONARY_ORDER_EDIT",
  AUDIT_LOG_VIEW = "AUDIT_LOG_VIEW",
  DICTIONARY_COMPANY_VIEW = "DICTIONARY_COMPANY_VIEW",
  DICTIONARY_COMPANY_EDIT = "DICTIONARY_COMPANY_EDIT",
  DICTIONARY_ROTE_MARK_FOR_DELETION = "DICTIONARY_ROTE_MARK_FOR_DELETION",
  DICTIONARY_ROTE_CONTROL_UPDATING_RATES = "DICTIONARY_ROTE_CONTROL_UPDATING_RATES",
  DICTIONARY_ROTE_UPDATING_RATES = "DICTIONARY_ROTE_UPDATING_RATES",
}

export default USER_PERMISSIONS;
