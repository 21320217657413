import styled from "styled-components";

const ControlLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-gray-900);
  margin-bottom: 6px;
  margin-right: 4px;
`;

export default ControlLabel;
